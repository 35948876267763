import { inject } from 'vue'
import { useStore } from '../../store/useStore'

import type { AS_NonStandardCart } from '../../../../types'

const useGroupCheckbox = () => {
  const { actions } = useStore()
  const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')

  const getIsChecked = (group) => {
    return group.groupInfo.isChecked === '1'
  }

  const getCheckedDisabled = (group) => {
    return !group.content?.length
  }

  const onClickGroupChecked = async (group) => {
    if (getCheckedDisabled(group)) return

    const isChecked = !getIsChecked(group)

    const cartIdList: any[] = []
    const appendIdList: any[] = []
    const goodsSnList: any[] = []
    group.content.forEach(item => {
      cartIdList.push(item.id)
      appendIdList.push(...item.appendIds)
      goodsSnList.push(item.product.goods_sn)
    })

    analysisInstance?.triggerNotice({
      id: 'click_select_someproduct.comp_non-standard-cart',
      data: {
        select_type: group.groupInfo.type === 'applicable' ? 4 : 5,
        is_select: isChecked ? 1 : 0,
        goods_sn: goodsSnList.join(),
      },
    })

    const cartUpdateParams = {
      cartIdList,
      operationType: isChecked ? '1' : '2',
      appendIdList: Array.from(new Set(appendIdList)),
    }
    await actions.updateCartItemCheckbox(cartUpdateParams)
  }

  return {
    getIsChecked,
    getCheckedDisabled,
    onClickGroupChecked,
  }
}

export {
  useGroupCheckbox,
}
