<template>
  <div class="bsc-nsc-cart-error">
    <SFeedback
      :title="language.PageLoadErrorTitle"
      :content="language.PageLoadErrorMsg"
    >
      <template #icon>
        <img
          src="https://img.ltwebstatic.com/images3_acp/2024/01/27/39/17063628484832cd94d9173559d898b6a6853baee5.png"
          class="bsc-nsc-cart-error__image"
        />
      </template>
    </SFeedback>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SFeedback } from '@shein-aidc/sui-feedback/mobile'

const { $language } = useAppConfigs()

const language = computed(() => $language.BNonStandardCart)

</script>

<style lang="less">
.bsc-nsc-cart-error {
  padding-top: 24px;

  &__image {
    width: 120px;
    height: 120px;
  }
}
</style>
