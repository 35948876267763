import { computed } from 'vue'

import type { C_NonStandardCart } from '../../../types'

export const getGetters = ({ state }) => {
  const isAutoUseCoupon = computed(() => state.abtInfo.Cartshowcoupon?.param?.cart_show_coupon_switch === 'on')
  const isAutoUsePoint = computed(() => (state.abtInfo.cartautopoints?.param?.is_auto_point || '').split('_')[0] === '1')
  const isCartBestCouponPrice = computed(() => state.abtInfo.cartPriceStyle?.param?.cart_best_coupon_price === 'on')
  const isNewCartrowVision = computed(() => state.abtInfo.NewCartrowVision?.param?.new_cartrow_vision === 'on')
  const checkboxGap = computed(() => isNewCartrowVision.value ? 16 / 75 + 'rem' : 20 / 75 + 'rem')

  const remoteThemeVars = computed<C_NonStandardCart.CustomThemeVars>(() => ({
    '--bsc-nsc-background-image': `url(${state.description.backgroundImg})`,
    '--bsc-nsc-delete-dialog-background-image': `url(${state.description.deleteBackgroundImg})`,
  }))

  const cartList = computed<any[]>(() => {
    return state.groups?.reduce?.((acc, cur) => acc.concat(cur.content || []), []) || []
  })
  const cartIds = computed(() => cartList.value.map((item) => item.id))
  const checkedCartList = computed(() => cartList.value.filter((item) => item.is_checked == '1'))
  const checkedCartListNum = computed(() => checkedCartList.value.length)

  return {
    isAutoUseCoupon,
    isAutoUsePoint,
    isCartBestCouponPrice,
    isNewCartrowVision,
    checkboxGap,
    remoteThemeVars,
    cartList,
    cartIds,
    checkedCartList,
    checkedCartListNum,
  }
}
