import { inject } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { useStore } from '../../store/useStore'
import { useBridge } from '../../bridge/useBridge'

import type { AS_NonStandardCart } from '../../../../types'

const useCartItemSaleAttr = () => {
  const { $language } = useAppConfigs()
  const { actions } = useStore()
  const { openPopupAddCartDialog } = useBridge()
  const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')!

  const updateCartItemSaleAttr = async ({ item, event }) => {
    if (event.isCustomGoods) {
      SToast($language.BNonStandardCart.CustomGoodsModifyMsg)
      return
    }

    const cartUpdateParams = await openPopupAddCartDialog(item, { analysisInstance })
    if (cartUpdateParams === 'skip') return

    const res = await actions.updateCartItemSaleAttr(cartUpdateParams)
    analysisInstance?.triggerNotice({
      id: 'click_update_popup_updatebutton.comp_non-standard-cart',
      data: {
        update_result: res ? 1 : 0,
        sku_id: item.product?.goods_sn ?? '-',
        mall_code: item.mall_code ?? '-',
      },
    })
  }

  return {
    updateCartItemSaleAttr,
  }
}

export {
  useCartItemSaleAttr,
}
