import { createApp, inject } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { useStore } from '../../store/useStore'
import { getCartItemCategory } from '../cart-item/useCartItemCategory'

import type { AS_NonStandardCart } from '../../../../types'

let instance
const mountComponent = () => {
  return new Promise(resolve => {
    import('./DeleteDialog.vue').then(module => {
      const component = module.default
      const app = createApp(component)
      const container = document.createElement('div')
      document.body.appendChild(container)
      instance = app.mount(container)
      resolve(true)
    }).catch(() => resolve(false))
  })
}

const useDeleteDialog = () => {
  const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')

  const { $language } = useAppConfigs()
  const { state, getters } = useStore()

  const showCartDeleteDialog = async ({ item }) => {

    const { isPresent } = getCartItemCategory(item)

    // 赠品不允许删除
    if (isPresent && state.featureConfig.cartItem?.disabledGiftDelete) {
      SToast($language.BNonStandardCart.FreeGiftEditMsg)
      return
    }
    // 必须保留至少一个商品
    const normalDelIdList = Array.from(new Set([item.id, ...item.appendIds])).filter(id => getters.cartIds.value.includes(id))
    if (state.featureConfig.requireAtLeastOne && getters.checkedCartListNum.value <= normalDelIdList.length && item.is_checked == '1') {
      SToast($language.BNonStandardCart.RequireAtLeastOneMsg)
      return false
    }

    if (!instance) {
      const res = await mountComponent()
      if (!res) return
    }

    instance.show({ item, analysisInstance })
  }

  return {
    showCartDeleteDialog,
  }
}

export {
  useDeleteDialog,
}
