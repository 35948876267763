<template>
  <div
    class="bsc-nsc-group"
    :style="{ '--bsc-nsc-group-sticky-top': `${stickyTop}px` }"
  >
    <div
      ref="sentinel"
      class="bsc-nsc-group__sentinel"
      :style="{ top: `-${stickyTop}px` }"
    ></div>
    <div
      class="bsc-nsc-group__header"
      :class="{
        'bsc-nsc-group__header_sticky': !disabled
      }"
    >
      <slot
        name="header"
        :is-sticky="isSticky"
      ></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useIntersectionObserver } from '../compositions/useIntersectionObserver'

defineProps({
  stickyTop: {
    type: Number,
    default: 0,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const sentinel = ref(null)
const isSticky = ref(false)

useIntersectionObserver(
  sentinel,
  ({ isVisible }) => isSticky.value = !isVisible,
  { threshold: [1] },
)
</script>

<style lang="less">
.bsc-nsc-group {
  position: relative;

  &__sentinel {
    position: absolute;
    height: 1px;
    width: 100%;
  }

  &__header {
    position: relative;
    z-index: 2;

    &.bsc-nsc-group__header_sticky {
      position: sticky;
      top: var(--bsc-nsc-group-sticky-top, 0);
    }
  }
}
</style>
