<template>
  <DropdownBox
    ref="dropdownBoxRef"
    closeable
    disabled-overscroll-behavior
    :max-height="featureConfig.maxHeight ?? 60"
    :teleport="featureConfig.teleport || ''"
    :close-on-click-overlay="featureConfig.closeOnClickOverlay !== false"
    :fixed="featureConfig.fixed"
    :body-class="[
      dropdownBoxBodyClass,
      featureConfig.customDropdownBoxBodyClass,
    ]"
    :body-style="dropdownBoxBodyStyle"
    :content-style="dropdownBoxContentStyle"
    :class="[
      'bsc-non-standard-cart',
      featureConfig.customClass,
    ]"
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
  >
    <template #top>
      <slot name="top"></slot>
    </template>
    <template #default="{ maxHeight }">
      <SwipeGroup
        ref="swipeGroupRef"
        accordion
      >
        <CartContainer
          :max-height="maxHeight"
          @back-to-add="close"
        />
      </SwipeGroup>
    </template>
    <template #bottom>
      <CartBackTop />
    </template>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </DropdownBox>
  <Teleport to="body">
    <SLoading
      :show="state.showLoading"
      type="curpage"
    />
  </Teleport>
</template>

<script name="BNonStandardCart" setup lang="ts">
import { ref, nextTick, onMounted, provide, getCurrentInstance } from 'vue'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { DropdownBox, SwipeGroup } from '@shein-aidc/bs-cart-components-mobile'
import CartContainer from './cart-container.vue'
import CartBackTop from './components/cart-back-top/CartBackTop.vue'
import { useStore } from './store/useStore'
import { useBridge } from './bridge/useBridge'
import { useTheme } from './compositions/useTheme'
import { scrollToElement } from './utils'
import { UPDATE_CART_INFO_ACTIONS } from './utils/constants'


import type { C_NonStandardCart } from '../../types'

const emit = defineEmits(['open', 'opened', 'close', 'closed'])

const props = withDefaults(defineProps<C_NonStandardCart.IProps>(), {
  customThemeVars: () => ({}),
  featureConfig: () => ({}),
  callbacks: () => ({}),
})

// 埋点源:
import { useAnalysis } from '../../common/analysisSource'
const analysisInstance = useAnalysis(props.analysisSource)
provide('analysisInstance', analysisInstance)
// 埋点源: END

const { state, actions } = useStore()
const { goCheckout } = useBridge()
const { dropdownBoxBodyStyle, dropdownBoxContentStyle } = useTheme()

const dropdownBoxBodyClass = ref<string>()
const instance = getCurrentInstance()!
dropdownBoxBodyClass.value = `j-bsc-cart-dropdown-box__body-${instance.uid}`
state.dropdownBoxBodyClass = `.${dropdownBoxBodyClass.value}`

const initState = () => {
  state.customThemeVars = props.customThemeVars
  state.customFetchParams = props.customFetchParams
  state.featureConfig = props.featureConfig
  state.callbacks = props.callbacks
  if (props.quickAddCartStandard) state.quickAddCartStandard = props.quickAddCartStandard
  state.analysisConfig = props.analysisConfig
}

const dropdownBoxRef = ref()
const swipeGroupRef = ref()
const open = async (openConfig?: C_NonStandardCart.OpenConfig) => {
  swipeGroupRef.value.closeAll()

  initState()

  analysisInstance.triggerNotice({
    id: 'expose_cart_pop.comp_non-standard-cart',
  })

  dropdownBoxRef.value?.open()

  await actions.fetchCart()

  if (openConfig?.scrollToCartItemId) {
    await nextTick()
    const scrollEl = document.querySelector(`${state.dropdownBoxBodyClass} .bsc-cart-dropdown-box__content`)
    const targetEl = document.querySelector(`${state.dropdownBoxBodyClass} .j-bsc-nsc-cart-item-${openConfig.scrollToCartItemId}`)
    if (!scrollEl || !targetEl) return
    scrollToElement(scrollEl, targetEl, !!openConfig.scrollWithHighlight)
  }
}
const close = () => {
  dropdownBoxRef.value?.close()
}
const calcFooterOffset = async () => {
  if (props.featureConfig?.disabledDeleteAnimation) {
    dropdownBoxRef.value?.calcOffset()
  } else {
    state.featureConfig.disabledDeleteAnimation = true
    await nextTick()
    dropdownBoxRef.value?.calcOffset()
    await nextTick()
    state.featureConfig.disabledDeleteAnimation = false
  }
}

const onOpen = () => {
  emit('open')
}
const onOpened = () => {
  emit('opened')
}
const onClose = () => {
  emit('close')
}
const onClosed = () => {
  analysisInstance?.triggerNotice({
    id: 'click_closecart.comp_non-standard-cart',
  })
  emit('closed')
}

const refetchData = () => {
  initState()

  actions.fetchCart({ action: UPDATE_CART_INFO_ACTIONS.REFETCH })
}

onMounted(() => {
  initState()

  actions.fetchCart()

  analysisInstance?.triggerNotice({
    id: 'page_view.comp_non-standard-cart',
    data: {
      state: props.analysisConfig.state ?? '-',
      type: props.analysisConfig.type ?? '-',
    },
  })
})

const _goCheckout = (data) => {
  return goCheckout({ analysisInstance }, data)
}

defineExpose({
  open,
  close,
  calcFooterOffset,
  refetchData,
  goCheckout: _goCheckout,
})
</script>

<style lang="less">
.bsc-nsc_highlight {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -99;
    animation: bsc-nsc-animation_highlight 1s linear 0s 2;
    pointer-events: none;
  }
}

/* 定义关键帧动画 */
@keyframes bsc-nsc-animation_highlight {
  /* 动画分四个阶段：从原始色到高亮色，再回到原始色，然后循环两次 */
  0%,
  50%,
  100% {
    background: #fff;
  }
  25%,
  75% {
    background: #fffbee;
  }
}

// 兼容代码
// sui-toast 组件在部分机型样式异常
// 待组件库修复后删除
.sui-toast { font-size: 12px; }
</style>
