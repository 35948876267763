import { computed } from 'vue'
import Wing from '@shein/wing'
import { getLocalStorage } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { useStore } from '../store/useStore'
import { initWing } from './initWing'
import { wingWithDuration } from './utils'
import { getAppVersionNumber, getImageRatio } from '../utils'
import { get$options } from '../../../common/analysisSource/sources/standard'
import { getCartItemIsNewUsersBonus } from '../components/cart-item/useCartItemCategory'

import type { AS_NonStandardCart } from '../../../types'

let init = false
let lock = false
let quickAddCartInstance

// eslint-disable-next-line max-lines-per-function
const useBridge = () => {
  const { $envs, $getters, $language } = useAppConfigs()

  const _Wing = computed({
    get() {
      if (!init) {
        initWing()
        init = true
      }
      return Wing
    },
    set() {},
  })

  const getCartPreAddress = async () => {
    if ($envs.project === 'pwa') {
      const addressCookie = getLocalStorage('addressCookie') || {}
      const localCache = JSON.parse(window.localStorage.getItem('localCache') || '{}')
      return {
        countryId: addressCookie?.countryId || '',
        addressId: addressCookie?.addressId || '',
        state: localCache?.state || '',
        city: localCache?.city || '',
        district: localCache?.district || '',
        postcode: localCache?.postcode || '',
        stateId: addressCookie?.stateId || '',
        cityId: addressCookie?.cityId || '',
      }
    }

    if ($envs.project === 'h5') {
      if (getAppVersionNumber() < 1178) {
        return {
          countryId: '',
          addressId: '',
          state: '',
          city: '',
          district: '',
          postcode: '',
          stateId: '',
          cityId: '',
        }
      }
      const res: any = await wingWithDuration(_Wing.value.post('CartBridge/get_cart_pre_address', {}))
      return {
        countryId: res?.country_id || '',
        addressId: res?.address_id || '',
        state: res?.state || '',
        city: res?.city || '',
        district: res?.district || '',
        postcode: res?.postcode || '',
        stateId: res?.state_id || '',
        cityId: res?.city_id || '',
      }
    }

    return {}
  }

  const getUserLocalSizeCountry = async () => {
    if ($envs.project === 'pwa') {
      return window.localStorage.getItem('last_select_country') || ''
    }

    if ($envs.project === 'h5') {
      const res: any = await wingWithDuration(_Wing.value.postBridge(
        'WingStorage/get',
        {
          key: 'goods_detail_size_country_code',
        },
      ))
      return res?.data || ''
    }

    return ''
  }

  const openPopupAddCartDialog = (item, { analysisInstance }: { analysisInstance: AS_NonStandardCart.AnalysisInstance }) => {
    const { state } = useStore()

    // pwa 中间层过滤了新人专享价，无需处理新人专享价逻辑
    if ($envs.project === 'pwa') {
      if (!state.quickAddCartStandard || !state.quickAddCartStandard.getInstance || !state.quickAddCartStandard.SalesAttrUpdatePlugin) {
        throw new Error('quickAddCartStandard is required')
      }
      return new Promise(r => {

        if (!quickAddCartInstance) {
          quickAddCartInstance = state.quickAddCartStandard.getInstance()
        }

        const sku_sale_attr = item.product?.sku_sale_attr || {}
        const selectedAttrIndex: any = {}
        if (item.attr?.attr_id) {
          selectedAttrIndex[item.attr?.attr_id] = {
            attr_value_id: item.attr?.attr_value_id,
          }
        }
        sku_sale_attr &&
          sku_sale_attr.forEach((item) => {
            if (!item.is_main) {
              selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
            }
          })
        getImageRatio(item.product?.goods_img).then((imgRatio) => {
          quickAddCartInstance.open(
            {
              goods_id: item.product?.goods_id || '',
              mallCode: item.mall_code,
            },
            {
              plugins: [
                state.quickAddCartStandard.SalesAttrUpdatePlugin(async (context) => {
                  r({
                    cartId: item.id,
                    mallCode: context?.mallCode,
                    goodsId: context?.productInfo?.goods_id,
                    skuCode: context?.skuInfo?.sku_code,
                    quantity: context?.quantity,
                    appendIdList: item.appendIds,
                    isChecked: item.is_checked,
                  })
                }),
              ],
              salesAttrConfig: {
                selectedAttrIndex,
              },
              analysisConfig: {
                code: 'cart',
                from: 'cart',
                source: 'page',
              },
              extendedParam: {
                imgRatio,
                quantity: +item.quantity,
              },
              featureConfig: {
                needFavoriteButton: false,
              },
              callbacks: {
                onDrawerStatusChange: (show) => {
                  show && analysisInstance?.triggerNotice({
                    id: 'expose_update_popup.comp_non-standard-cart',
                    data: {
                      sku_id: item.product?.goods_sn ?? '-',
                    },
                  })
                },
                onMallClick: () => {
                  analysisInstance?.triggerNotice({
                    id: 'click_update_popup_changemall.comp_non-standard-cart',
                    data: {
                      sku_id: item.product?.goods_sn ?? '-',
                    },
                  })
                },
              },
            },
          )
        })
      })
    }
    // app h5 新人专享价是可编辑尺码的，编辑尺码需要携带新人专享价活动信息
    if ($envs.project === 'h5') {
      let _r
      return new Promise(r => {
        _r?.('skip')
        _r = r
        let newcomerItemPopupParams
        let newcomerItemUpdateParams
        // 新人专享价商品需要携带新人专享价活动信息
        if (getCartItemIsNewUsersBonus(item)) {
          const promotionId = item.aggregateProductBusiness.promotion_id
          const typeId = item.aggregateProductBusiness.type_id
          if (promotionId && typeId) {
            newcomerItemPopupParams = {
              promotionId,
              promotionProductMask: 1,
            }
            newcomerItemUpdateParams = {
              promotion_type: typeId,
              promotion_id: promotionId,
              promotion_product_mask: '1',
            }
          }
        }
        const { page_id, page_name, page_param } = get$options()
        _Wing.value.post('AddCart/popup_addcart_dialog', {
          is_update_goods_data: '1',
          mall_code: item.mall_code,
          goods_id: item.product.goods_id,
          selected_sku_code: item.product.sku_code,
          ...newcomerItemPopupParams,
          showDetailEntry: 0,
          page_id,
          page_name,
          page_param,
          start_time: `${$getters.SaPageInfo.start_time || ''}`.slice(0, 10),
          activity_from: '',
          trace_id: '', // bi埋点traceid
        })

        analysisInstance?.triggerNotice({
          id: 'expose_update_popup.comp_non-standard-cart',
          data: {
            sku_id: item.product?.goods_sn ?? '-',
          },
        })

        _Wing.value.on('updateGoodsDataFromApp', (res) => {
          _r = null
          r({
            cartId: item.id,
            mallCode: res.mall_code,
            goodsId: res.goods_id,
            skuCode: res.sku_code,
            quantity: item.quantity,
            appendIdList: item.appendIds,
            isChecked: item.is_checked,
            ...newcomerItemUpdateParams,
          })
        })
      })
    }
  }

  /**
   * checkoutParams
   * @param scene
   * @param extra_activity_info
   * @param page_form_type
   */
  const goCheckout = async ({
    analysisInstance,
  }: { analysisInstance: AS_NonStandardCart.AnalysisInstance }, { pageFrom, checkoutParams }: { pageFrom, checkoutParams?: any }) => {
    if ($envs.project !== 'h5') {
      console.error('该方法只支持 app h5 调用')
      return
    }

    if (lock) {
      return {
        code: -4,
        msg: '正在跳转中',
      }
    }

    try {
      lock = true

      const { state, actions } = useStore()

      const commonExtraData = {
        ...state.biData.cartCheckoutBiData,
        page_from: pageFrom,
      }

      // 校验顺序：是否有已勾选商品 -> 勾选商品数量是否超过限制 -> 是否已登录

      const [fetchCartRes, checkStockRes] = await Promise.all([
        actions.fetchCart({ disabledPageLoading: true }),
        actions.checkStock(),
      ])

      if (!fetchCartRes) {
        SToast($language.BNonStandardCart.SystemErrorMsg)
        analysisInstance?.triggerNotice({
          id: 'click_checkout.comp_non-standard-cart',
          data: {
            result: 2,
            result_reason: 'data_error',
            ...commonExtraData,
          },
        })
        return {
          code: -3,
          msg: '获取购物车数据失败',
        }
      }

      // 校验是否有已勾选商品
      const sum = state.biData.cartCheckoutBiData?.checkout_sum || 0
      if (+sum === 0) {
        const hasPayMemberGift = state.groups.find(group => {
          return group.content.find(item => item.isPayMemberGift === '1')
        })
        if (hasPayMemberGift) {
          SToast($language.BNonStandardCart.CheckoutWithGiftMsg)
        } else {
          SToast($language.BNonStandardCart.CheckoutMsg)
        }
        analysisInstance?.triggerNotice({
          id: 'click_checkout.comp_non-standard-cart',
          data: {
            result: 2,
            result_reason: 'not_check',
            ...commonExtraData,
          },
        })
        return {
          code: -1,
          msg: '未勾选商品',
        }
      }

      // 校验勾选商品数量是否超过限制
      if (checkStockRes?.code == '10126002') {
        SToast(checkStockRes?.msg)
        return {
          code: -1,
          msg: '勾选商品数量超过99',
        }
      }

      // 校验是否已登录
      if (!$getters.isLogin) {
        analysisInstance?.triggerNotice({
          id: 'click_checkout.comp_non-standard-cart',
          data: {
            result: 2,
            result_reason: 'not_login',
            ...commonExtraData,
          },
        })

        await _Wing.value.navigation('/account/login', {
          login_page_type: '1',
        })

        return {
          code: -2,
          msg: '未登录注册',
        }
      }

      const metaInfo = state.metaInfo || {}
      const checkoutBiData = state.biData.cartCheckoutBiData || {}
      const data = {
        from_new_cart: true,

        business_mode_list: metaInfo.businessModelForCheckout,
        is_multi_mall: metaInfo.isMultiMall === '1',
        goods_type: metaInfo.goodsType,
        country_id: state.preAddressInfo.countryId || state.customFetchParams.cartParams?.countryId || '',

        report_map: {
          bi_page_map: {
            ...checkoutBiData,
            page_from: pageFrom,
          },
        },

        ...checkoutParams,
      }
      console.log(`%c 跳转下单页的参数：${ JSON.stringify(data) }`, 'background:#37af7a;color:#fff;font-size:16px;padding: 4px;')
      await _Wing.value.navigation('/pay/checkout', data)

      analysisInstance?.triggerNotice({
        id: 'click_checkout.comp_non-standard-cart',
        data: {
          result: 1,
          ...commonExtraData,
        },
      })

      return {
        code: 0,
        msg: '跳转下单页成功',
      }
    } catch (error) {
      console.error('error', error)
    } finally {
      lock = false
    }
  }

  return {
    getCartPreAddress,
    getUserLocalSizeCountry,
    openPopupAddCartDialog,
    goCheckout,
  }
}

export {
  useBridge,
}
