export const POS_KEYS = [
  'CartNumberEditStyle',
  'cartPriceStyle',
  'NewCartrowVision',
  'Cartshowcoupon',
  'cartautopoints',
]

/**
 * 触发更新购物车信息的 action
 */
export const enum UPDATE_CART_INFO_ACTIONS {
  /**
   * 查车
   */
  FETCH = 'fetch',
  /**
   * 更新数量
   */
  UPDATE_QUANTITY = 'updateQuantity',
  /**
   * 删除
   */
  DELETE = 'delete',
  /**
   * 勾选 / 取消勾选
   */
  MODIFY = 'modify',
  /**
   * 更新销售属性
   */
  UPDATE_ATTR = 'updateAttr',
  /**
   * 重新查车，外部通知非标刷新数据
   */
  REFETCH = 'refetch',
}
