<template>
  <div
    class="bsc-cart-swipe-cell"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
    @touchcancel="onTouchEnd"
    @click="onClick"
  >
    <div
      ref="rootRef"
      class="bsc-cart-swipe-cell__wrapper"
      :style="wrapperStyle"
    >
      <div
        v-if="slots.left"
        ref="leftRef"
        class="bsc-cart-swipe-cell__left"
      >
        <slot name="left"></slot>
      </div>
      <slot></slot>
      <div
        v-if="slots.right"
        ref="rightRef"
        class="bsc-cart-swipe-cell__right"
      >
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSlots, computed, reactive, ref, inject, getCurrentInstance, onMounted, onUnmounted } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useTouch } from '../compositions/useTouch'

// 通过 inject 获取 SwipeCellGroup 提供的 swipeCell
const { register, unregister, notify } = inject<any>('swipeCell') || {}
const insatance = getCurrentInstance()

const props = defineProps({
  leftWidth: {
    type: Number,
  },
  rightWidth: {
    type: Number,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const slots = useSlots()
const touch = useTouch()

const { $envs } = useAppConfigs()

const isDef = val => val !== undefined && val !== null
const clamp = (n, min, max) => Math.min(max, Math.max(min, n))

let startOffset
let opened
let lockClick
const rootRef = ref()
const leftRef = ref()
const rightRef = ref()
const state = reactive({
  offset: 0,
  dragging: false,
})
const wrapperStyle = computed(() => {
  return {
    transform: `translate3d(${state.offset}px, 0, 0)`,
    transitionDuration: state.dragging ? '0s' : '.6s',
  }
})
const leftWidth = computed(() => {
  if ($envs.cssRight) {
    return isDef(props.rightWidth) ? props.rightWidth : rightRef.value?.clientWidth || 0
  }
  return isDef(props.leftWidth) ? props.leftWidth : leftRef.value?.clientWidth || 0
})
const rightWidth = computed(() => {
  if ($envs.cssRight) {
    return isDef(props.leftWidth) ? props.leftWidth : leftRef.value?.clientWidth || 0
  }
  return isDef(props.rightWidth) ? props.rightWidth : rightRef.value?.clientWidth || 0
})
const emit = defineEmits(['open', 'close'])

const open = (side: 'left' | 'right') => {
  state.offset = side === 'left' ? leftWidth.value : -rightWidth.value
  notify?.(insatance.uid, side)
  if (!opened) {
    opened = true
    emit('open', side)
  }
}
const close = (side: 'outside' | 'left' | 'right') => {
  state.offset = 0

  if (opened) {
    opened = false
    emit('close', side)
  }
}
const toggle = side => {
  const offset = Math.abs(state.offset)
  const THRESHOLD = 0.15
  const threshold = opened ? 1 - THRESHOLD : THRESHOLD
  const width = side === 'left' ? leftWidth.value : rightWidth.value

  if (width && offset > width * threshold) {
    open(side)
  } else {
    close(side)
  }
}
const onTouchStart = e => {
  if (props.disabled) return
  startOffset = state.offset
  touch.start(e)
}

const onTouchMove = e => {
  if (props.disabled) return
  const { deltaX } = touch
  touch.move(e)

  if (touch.isVertical()) return
  lockClick = true
  state.dragging = true
  e.preventDefault()
  e.stopPropagation()
  state.offset = clamp(startOffset + deltaX.value, -rightWidth.value, leftWidth.value)
}

const onTouchEnd = (e) => {
  if (!state.dragging) return
  e.stopPropagation()
  state.dragging = false
  toggle(state.offset > 0 ? 'left' : 'right')

  // compatible with desktop scenario
  setTimeout(() => {
    lockClick = false
  }, 0)
}

const onClick = (e) => {
  if (lockClick) {
    e.stopPropagation()
    return
  }
  close('outside')
}

onMounted(() => {
  register?.(insatance.uid, {
    close,
  })
})
onUnmounted(() => {
  unregister?.(insatance.uid)
})

defineExpose({
  open,
  close,
})
</script>

<style lang="less">
.bsc-cart-swipe-cell {
  position: relative;
  overflow: hidden;
  cursor: grab;

  &__wrapper {
    transition-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1);
    transition-property: transform;
  }

  &__left,
  &__right {
    position: absolute;
    top: 0;
    height: 100%;
  }

  &__left {
    left: 0;
    transform: translate3d(-100%, 0, 0);
  }

  &__right {
    right: 0;
    transform: translate3d(100%, 0, 0);
  }
}
</style>
