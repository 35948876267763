<template>
  <CartSkeleton
    v-show="state.pageLoading"
    :height="maxHeight"
  />
  <CartContent
    v-show="!state.pageLoading && !state.pageError"
    @back-to-add="onBackToAdd"
  />
  <CartError v-if="state.pageError" />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import { useStore } from './store/useStore'
import CartSkeleton from './layout/CartSkeleton.vue'
import CartContent from './components/cart-content/CartContent.vue'

const CartError = defineAsyncComponent(() => import('./components/CartError.vue'))

const emit = defineEmits(['backToAdd'])

defineProps({
  maxHeight: {
    type: String,
    default: '',
  },
})

const { state } = useStore()

const onBackToAdd = () => {
  emit('backToAdd')
}
</script>
