import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { useStore } from '../../../mobile/src/store/useStore'

import type { AS_NonStandardCart } from '../../../types'

export const get$options = () => {
  const { state } = useStore()
  const { $getters } = useAppConfigs()
  return {
    page_id: '6277',
    page_name: 'page_non_standard_cart',
    page_param: {
      click_from: state.analysisConfig.pageParam?.click_from ?? 'non_standard_cart_icon',
      ...$getters.SaPageInfo?.page_param,
    },
  }
}

const getPageFrom = () => {
  const { $getters } = useAppConfigs()
  return $getters.SaPageInfo.page_name ?? '-'
}

const standard: AS_NonStandardCart.AnalysisFuncs = {
  'page_view.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'expose_cart_pop.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      page_from: getPageFrom(),
      $options: get$options(),
    }
  },
  'expose_goods_list.comp_non-standard-cart': ({ extraData }) => {
    const {
      goodsId,
      skuId,
      spuId = '',
      index,
      usPrice,
      usOriginPrice,
      attrStock,
      insurable,
      unuseCouponTips,
      tspId,
      mallCode,
      showEvoluShein,
      priceTagText,
    } = extraData
    const page = 1
    const operation = 1
    const recMark = ''
    const otherMark: string[] = []
    showEvoluShein && otherMark.push('show_evolushein')
    if (priceTagText) otherMark.push(`price_pr_${priceTagText}`)

    const goods_list = `${goodsId}\`${skuId}\`${spuId}\`${
      +index + 1
    }\`${page}\`${operation}\`${recMark}\`\`pri_${usPrice}|pri_${usOriginPrice}\`${otherMark.join('|')}\`left_${attrStock || ''}${insurable == 1 ? '|insured_1' : ''}|${unuseCouponTips}${
      tspId ? `|show_icon_${tspId}` : ''
    }\`\`mall_${mallCode}`

    return {
      traceid: '',
      abtest: '',
      activity_from: 'goods_list',
      style: 'popup',
      goods_list,
      $options: get$options(),
    }
  },
  'expose_delete_tips.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_goods_delete.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'expose_goods_number.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_goods_add_qty.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_goods_sub_qty.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_cart_quantity_edit.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'expose_update_popup.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  // pwa 上报
  'click_update_popup_changemall.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  // pwa 上报
  'click_update_popup_updatebutton.comp_non-standard-cart': ({ extraData }) => {
    return {
      scene: '-',
      promotion_type: '',
      source: '',
      ...extraData,
      $options: get$options(),
    }
  },
  'expose_gobacktoadd.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_gobacktoadd.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'expose_backtop.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_backtop.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_select_someproduct.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_goods_del_button.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_closecart.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      $options: get$options(),
    }
  },
  'click_checkout.comp_non-standard-cart': ({ extraData }) => {
    return {
      ...extraData,
      use_from: getPageFrom(),
      // 该埋点 page_name 归属具体业务
      // $options: get$options(),
    }
  },
}

export default standard
