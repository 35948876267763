import { ref, computed, nextTick, inject } from 'vue'
import { debounce } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getConstant } from '@shein-aidc/bs-cart-item-mobile'
import { useStore } from '../../store/useStore'
import { usePopoverPlacemen } from './usePopoverPlacemen'
import { useDeleteDialog } from '../delete-dialog/useDeleteDialog'
import { useCartItemSaleAttr } from './useCartItemSaleAttr'
import { useCartItemQty } from './useCartItemQty'
import { useCartItemCategory } from './useCartItemCategory'

import type { AS_NonStandardCart } from '../../../../types'
import type { Mobile } from '@shein-aidc/bs-cart-item-mobile'

// eslint-disable-next-line max-lines-per-function
const useCartItem = (item, index) => {
  const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')

  const { $envs } = useAppConfigs()
  const { state, getters, actions } = useStore()
  const { popoverPlacement, calcPopoverPlacemen } = usePopoverPlacemen()
  const { showCartDeleteDialog } = useDeleteDialog()
  const { updateCartItemSaleAttr } = useCartItemSaleAttr()
  const { updateCartItemQty } = useCartItemQty()
  const { isPresent, isAddBuy, isNewUsersBonus, isCustomGoods, isAdditionGoodsView } = useCartItemCategory(item)

  const isNewCartrowVision = computed(() => getters.isNewCartrowVision.value)
  const priceMode = computed(() => item.value.aggregateProductBusiness?.priceData?.priceMode || 'normal')

  const constant = getConstant()

  const language = ref({})

  const use: Mobile.MainCart[] = [
    'GoodsImg',
    'GoodsDiscountFlag',
    'GoodsBelt',
    'GoodsTitle',
    'GoodsSeriesFlag',
    'GoodsPrice',
    'GoodsDeleteButton',
    'GoodsSaleAttr',
    'GoodsQty',
    'QuickShipTag',
    'FreeShippingTag',
    'SFSTag',
    'EvoluTag',
    'MorePromotionTag',
    'DiscountNormalTag',
    'DiscountLimitTag',
    'EstimatedPrice',
    'GoodsRepickButton',
    'HolidayDeliveryTag',
    'BundleBuyTag',
  ]

  const config = ref<Mobile.Config<Mobile.MainCart>>({
    GoodsDiscountFlag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if ((priceMode.value !== 'normal' && state.featureConfig.cartItem?.hideDelPrice) || !!state.featureConfig.cartItem?.hideAllDiscount) {
          return {
            isShow: false,
          }
        }
        return {
          isShowMask: false,
        }
      },
    },
    GoodsBelt: {
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    GoodsSeriesFlag: {
      props() {
        return {
          // isShowMask: false,
        }
      },
    },
    QuickShipTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if (state.featureConfig.cartItem?.hideQuickShipTag) {
          return {
            isShow: false,
          }
        }
        return {
          isShowMask: false,
        }
      },
    },
    FreeShippingTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if (state.featureConfig.cartItem?.hideFreeShippingTag) {
          return {
            isShow: false,
          }
        }
        return {
          isShowMask: false,
        }
      },
    },
    EvoluTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    SFSTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    MorePromotionTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if (state.featureConfig.cartItem?.hideMorePromotionTag) {
          return {
            isShow: false,
          }
        }
        return {
          isShowMask: false,
          placemen: popoverPlacement.value,
        }
      },
      events: {
        onBeforeClick: ({ event }) => {
          if (!event.show) {
            const targetElement = document.querySelector(`${state.dropdownBoxBodyClass} .j-bsc-nsc-cart-item-${item.value.id} .bsc-cart-item-more-tag${isNewCartrowVision.value ? '-v2' : ''}`)
            if (!targetElement) return
            calcPopoverPlacemen(targetElement)
          }
        },
      },
    },
    GoodsImg: {
      props() {
        return {
          isLazyLoad: !state.featureConfig.cartItem?.disabledLazyLoad && index > 4,
          isClick: false,
          isShowMask: false,
        }
      },
    },
    GoodsTitle: {
      props() {
        return {
          isClick: false,
          isShowMask: false,
        }
      },
    },
    GoodsPrice: {
      props() {
        if (state.featureConfig.cartItem?.hideDelPrice) {
          if (priceMode.value !== 'normal') {
            return {
              salePriceColor: '#222',
              isShowOriginalPrice: false,
              isShowOriginalPricePopover: false,
              isShowSuggestPrice: false,
              isShowMask: false,
            }
          }
          return {
            isShowOriginalPrice: false,
            isShowMask: false,
          }
        }
        return {
          isShowMask: false,
        }
      },
    },
    GoodsDeleteButton: {
      events: {
        onClick: ({ data }) => {
          analysisInstance?.triggerNotice({
            id: 'click_goods_sub_qty.comp_non-standard-cart',
            data: {
              qty: data.value.quantity,
              goods_type: isPresent.value ? 'gift' : isAddBuy.value ? 'add-on' : '-',
            },
          })
          showCartDeleteDialog({ item: data.value })
        },
      },
    },
    GoodsSaleAttr: {
      props() {
        if (isCustomGoods.value) {
          return {
            isShowMask: true,
          }
        }
        return {
          isShowMask: false,
        }
      },
      events: {
        onClick: ({ data, event }) => {
          updateCartItemSaleAttr({ item: data.value, event })
        },
      },
    },
    GoodsQty: {
      events: {
        onExpose: ({ data }) => {
          analysisInstance?.triggerNotice({
            id: 'expose_goods_number.comp_non-standard-cart',
            data: {
              goods_id: data.value.product?.goods_id ?? '-',
              cart_id: data.value.id ?? '-',
            },
          })
        },
        onClickInput: () => {
          if (isNewUsersBonus.value) return
          analysisInstance?.triggerNotice({
            id: 'click_cart_quantity_edit.comp_non-standard-cart',
          })
        },
        onDelete: ({ data }) => {
          analysisInstance?.triggerNotice({
            id: 'click_goods_sub_qty.comp_non-standard-cart',
            data: {
              qty: data.value.quantity,
              goods_type: isPresent.value ? 'gift' : isAddBuy.value ? 'add-on' : '-',
            },
          })
          showCartDeleteDialog({ item: data.value })
        },
        onMinus: ({ data }) => {
          analysisInstance?.triggerNotice({
            id: 'click_goods_sub_qty.comp_non-standard-cart',
            data: {
              qty: data.value.quantity,
              goods_type: isPresent.value ? 'gift' : isAddBuy.value ? 'add-on' : '-',
            },
          })
        },
        onPlus: () => {
          analysisInstance?.triggerNotice({
            id: 'click_goods_add_qty.comp_non-standard-cart',
          })
        },
        onChange: debounce({
          func: ({ data, event }) => {
            const updateQty = (qty) => {
              nextTick(() => {
                data.value.quantity = qty
              })
            }
            updateCartItemQty({ item: data.value, qty: event.quantity, updateQty })
          },
          wait: 200,
        }),
      },
      props() {
        return {
          isNewUserGoods: false,
          isShowMask: false,
        }
      },
    },
    DiscountNormalTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if ((priceMode.value !== 'normal' && state.featureConfig.cartItem?.hideDelPrice) || !!state.featureConfig.cartItem?.hideAllDiscount) {
          return {
            isShow: false,
          }
        }
        return {
          isClick: false,
          isShowMask: false,
        }
      },
    },
    DiscountLimitTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if ((priceMode.value !== 'normal' && state.featureConfig.cartItem?.hideDelPrice) || !!state.featureConfig.cartItem?.hideAllDiscount) {
          return {
            isShow: false,
          }
        }
        return {
          isClick: false,
          isShowMask: false,
        }
      },
      events: {
        onCountDownEnd: () => {
          actions.fetchCart({ disabledPageLoading: true })
        },
      },
    },
    EstimatedPrice: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if ((priceMode.value !== 'normal' && state.featureConfig.cartItem?.hideDelPrice) || !!state.featureConfig.cartItem?.hideAllDiscount) {
          return {
            isShow: false,
          }
        }
        return {
          isClick: false,
          isShowMask: false,
        }
      },
      events: {
        onCountDownEnd: () => {
          actions.fetchCart({ disabledPageLoading: true })
        },
      },
    },
    GoodsRepickButton: {
      props({ data }) {
        const isBlindBoxFlag = data.value.blind_box_flag === 1
        const isH5 = $envs.project === 'h5'
        const hideBlindBoxRepickByH5 = isH5 && isBlindBoxFlag
        if (hideBlindBoxRepickByH5 || !state.featureConfig.cartItem?.showRepick) {
          return {
            isShow: false,
          }
        }
        return {
          maxWidth: '2.667rem',
        }
      },
      events: {
        onClick: ({ data }) => {
          state.callbacks.onRepick?.(JSON.parse(JSON.stringify(data.value || {})))
        },
      },
    },
    HolidayDeliveryTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    BundleBuyTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
  })

  return {
    constant,
    language,
    use,
    config,

    isAdditionGoodsView,
  }
}

export {
  useCartItem,
}
