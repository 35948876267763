import { injectLanguages } from '@shein-aidc/bs-sdk-libs-manager'
import NonStandardCart_PWALang from '@packages/bs/non-standard-cart/mobile/lang/pwa.json'
import NonStandardCart_H5Lang from '@packages/bs/non-standard-cart/mobile/lang/h5.json'

import type { DS_NonStandardCart } from '../../../types'

export const fetchLanguages: DS_NonStandardCart.APIS_THIS['fetchLanguages'] = async function () {
  let langPackages: typeof NonStandardCart_PWALang | typeof NonStandardCart_H5Lang
  switch (this.$envs.project) {
    case 'h5':
      langPackages = NonStandardCart_H5Lang
      break
    default:
      langPackages = NonStandardCart_PWALang
      break
  }
  return await injectLanguages({ langPackages })
}

export const fetchAbt: DS_NonStandardCart.APIS_THIS['fetchAbt'] = async function (params) {
  const res = await this.$schttp({
    url: '/abt/merge/get_abt_by_poskey',
    method: 'GET',
    params,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return res.data
}

export const fetchCart: DS_NonStandardCart.APIS_THIS['fetchCart'] = async function (data) {
  const res = await this.$schttp({
    url: '/order/mall/cart/shopping_bag/index',
    method: 'POST',
    data,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return res.data
}

export const updateCartItemCheckbox: DS_NonStandardCart.APIS_THIS['updateCartItemCheckbox'] = async function (data) {
  const res = await this.$schttp({
    url: '/order/mall/cart/shopping_bag/modify_check_status',
    method: 'POST',
    data,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return res.data
}

export const updateCartItemSaleAttr: DS_NonStandardCart.APIS_THIS['updateCartItemSaleAttr'] = async function (data) {
  const res = await this.$schttp({
    url: '/order/mall/cart/shopping_bag/update_attr',
    method: 'POST',
    data,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return res.data
}

export const updateCartItemQty: DS_NonStandardCart.APIS_THIS['updateCartItemQty'] = async function (data) {
  const res = await this.$schttp({
    url: '/order/mall/cart/shopping_bag/update',
    method: 'POST',
    data,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return res.data
}

export const delCartItemByIds: DS_NonStandardCart.APIS_THIS['delCartItemByIds'] = async function (data) {
  const res = await this.$schttp({
    url: '/order/mall/cart/shopping_bag/delete',
    method: 'POST',
    data,
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return res.data
}

export const getEntranceGuide: DS_NonStandardCart.APIS_THIS['getEntranceGuide'] = async function () {
  const res = await this.$schttp({
    url: '/order/cart/get_entrance_guide',
    method: 'POST',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
  })
  return res.data
}

/**
 * 商品库存校验
 */
export const checkStock: DS_NonStandardCart.APIS_THIS['checkStock'] = async function () {
  const res = await this.$schttp({
    url: '/order/cart/stock/check',
    method: 'GET',
    headers: {
      AppCurrency: this.$envs.currency,
      AppLanguage: this.$envs.appLanguage,
    },
    params: { is_old_version: 0 },
  })
  return res.data
}
