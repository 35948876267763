<template>
  <div class="bsc-cart-list-loading">
    <SLoading
      :show="true"
      size="medium"
    />
    <span
      v-if="slots.default"
      class="bsc-cart-list-loading__text"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { useSlots } from 'vue'
import { SLoading } from '@shein-aidc/sui-loading/mobile'

const slots = useSlots()
</script>

<style lang="less">
.bsc-cart-list-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  &__text {
    margin-left: 8/37.5rem;
  }
}
</style>
