import { ref } from 'vue'
import { useStore } from '../../store/useStore'

const usePopoverPlacemen = () => {
  const { state } = useStore()
  const popoverPlacement = ref('bottom')
  // 计算气泡的位置
  const calcPopoverPlacemen = (targetDom: Element) => {
    const rect = targetDom.getBoundingClientRect()
    const bodyMain = document.querySelector(`${state.dropdownBoxBodyClass} .bsc-cart-dropdown-box__content`)
    if (!bodyMain) return
    const bodyMainRect = bodyMain.getBoundingClientRect()
    const diffBottom = bodyMainRect.bottom - rect.bottom
    const diffTop = rect.top - bodyMainRect.top
    const horizontal = diffTop > diffBottom ? 'top' : 'bottom'
    popoverPlacement.value = horizontal
  }

  return {
    popoverPlacement,
    calcPopoverPlacemen,
  }
}

export {
  usePopoverPlacemen,
}
