import { reactive } from 'vue'

import type { C_NonStandardCart } from '../../../types'

export interface State {
  customThemeVars: C_NonStandardCart.CustomThemeVars
  defaultFetchParams: C_NonStandardCart.CustomFetchParams
  customFetchParams: C_NonStandardCart.CustomFetchParams
  featureConfig: C_NonStandardCart.FeatureConfig
  callbacks: C_NonStandardCart.Callbacks
  analysisConfig: C_NonStandardCart.AnalysisConfig
  quickAddCartStandard: C_NonStandardCart.QuickAddCartStandard
  dropdownBoxBodyClass?: string
  description: any
  groups: any[]
  biData: any
  pageLoading: boolean
  pageError: boolean
  showLoading: boolean
  language: any
  abtInfo: any
  preAddressInfo: any
  userLocalSizeCountry: any

  isFreeShipping: boolean
  metaInfo: any
}

export const state = reactive<State>({
  defaultFetchParams: {
    cartParams: {
      autoUseCoupon: '0',
      autoUsePoint: '0',
    },
    sceneData: {},
  },
  customFetchParams: {},
  customThemeVars: {},
  featureConfig: {},
  callbacks: {},
  analysisConfig: {
    state: '',
    type: '',
  },
  quickAddCartStandard: {},
  dropdownBoxBodyClass: undefined,
  description: {},
  groups: [],
  biData: {},
  pageLoading: false,
  pageError: false,
  showLoading: false,
  language: {},
  abtInfo: {},
  preAddressInfo: {},
  userLocalSizeCountry: '',

  isFreeShipping: false,
  metaInfo: {},
})
