import { debounce } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'

/**
 * 根据真实图片的比率映射比例字符串
 * 注意这里是 宽 / 高 的比率
 *
 */
export function closestRatio(imgRatio = 0) {
  const imgRatioMaps = [
    { key: 3 / 4, value: '3-4' },
    { key: 4 / 5, value: '4-5' },
    { key: 13 / 16, value: '13-16' },
    { key: 1 / 1, value: '1-1' },
  ]

  const closestObj = imgRatioMaps.reduce(function (prev, curr) {
    return Math.abs(curr.key - imgRatio) < Math.abs(prev.key - imgRatio) ? curr : prev
  })

  return closestObj.value
}

/**
 * 根据图片地址获取图片匹配的字符串比率
 * 注意这里是 宽 / 高 的比率
 *
 */
export function getImageRatio(imgSrc = '') {
  const { $envs } = useAppConfigs()
  return new Promise((resolve) => {
    const image = new Image()

    if ($envs.RESOURCE_SDK && $envs.RESOURCE_SDK.isSupportCropImage) {
      imgSrc = imgSrc.replace(/(_square)|(_squfix)/gi, '')
    }

    image.src = imgSrc

    image.onload = (e: Event) => {
      const target = e.target as HTMLImageElement
      const width = target.width
      const height = target.height
      const ratioString = closestRatio(width / height)

      resolve(ratioString)
    }

    image.onerror = () => {
      resolve(false)
    }
  })
}

export function domWithHighlight(element) {
  if (!element) return
  // 添加高亮动画类
  element.classList.add('bsc-nsc_highlight')
  // 让浏览器有时间渲染动画，然后移除类以便之后可以重新触发动画
  setTimeout(() => {
    element.classList.remove('bsc-nsc_highlight')
  }, 2000) // 动画总时间是 2s
}

export function scrollToElement(scrollEl: Element, targetEl: Element, withHighlight: boolean) {
  if (!scrollEl || !targetEl) return
  const { top: scrollElTop, height: scrollElHeight } = scrollEl.getBoundingClientRect()
  const { top: targetElTop, height: targetElHeight } = targetEl.getBoundingClientRect()
  const diff = targetElTop - scrollElTop + scrollEl.scrollTop - (scrollElHeight - targetElHeight) / 2
  if (scrollEl.scrollTo) {
    scrollEl.scrollTo({ top: diff, behavior: 'smooth' })
  } else {
    scrollEl.scrollTop = diff
  }

  // 闪烁
  if (withHighlight) {
    let onScroll = debounce({
      func: () => {
        scrollEl.removeEventListener('scroll', onScroll)
        // @ts-ignore
        scrollEl = null
        // @ts-ignore
        onScroll = null

        domWithHighlight(targetEl)
      },
      wait: 300,
    })
    // 存在滚动行为，等待滚动结束后执行高亮动画
    scrollEl.addEventListener('scroll', onScroll)

    // 兜底 手动执行一次，防止滚动事件未触发
    onScroll()
  }
}

export function getAppVersionNumber() {
  const version = typeof window !== 'undefined' ? window?.appComParams?.appversion : ''
  if (!version) {
    return 0
  }
  return +version?.replace?.(/\./g, '')
}
