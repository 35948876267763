import { computed } from 'vue'
import { useStore } from '../../store/useStore'

export const useCartItemCssVar = () => {
  const { getters } = useStore()
  // 是否调整购物车主图尺寸并展示新的商品行标签样式（UG-101110）
  const cartItemCssVar = computed(() => ({
    '--item-gap': getters.isNewCartrowVision.value ? '0.213rem' : '0.2667rem',
    '--template-left-width': getters.isNewCartrowVision.value ? '2.133rem' : '1.973rem',
    '--template-left-height': getters.isNewCartrowVision.value ? '2.133rem' : '1.973rem',
    '--checkbox-height': getters.isNewCartrowVision.value ? '2.133rem' : '1.973rem',
  }))
  return {
    cartItemCssVar,
  }
}
