<template>
  <SDialog
    v-model:visible="isShow"
    show-close
    class="bsc-nsc-delete-dialog"
    :style="deleteDialogThemeVars"
    @close-from-icon="onClickNo"
    @close-from-mask="onClickNo"
  >
    <template #title>
      <div class="bsc-nsc-delete-dialog__title">{{ $language.BNonStandardCart.DeleteDialogTitle }}</div>
    </template>
    <template #default>
      <div class="bsc-nsc-delete-dialog__item">
        <BMainCartItem
          v-if="item"
          :constant="constant"
          :language="language"
          :use="use"
          :data="item"
          :config="config"
        />
      </div>
    </template>
    <template #footer>
      <SButton
        :type="['primary']"
        class="bsc-nsc-delete-dialog__yes"
        @click="onClickYes"
      >
        {{ $language.BNonStandardCart.Delete }}
      </SButton>
      <SButton
        class="bsc-nsc-delete-dialog__no"
        @click="onClickNo"
      >
        {{ $language.BNonStandardCart.Cancel }}
      </SButton>
    </template>
  </SDialog>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { BMainCartItem } from '@shein-aidc/bs-cart-item-mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'

import { useStore } from '../../store/useStore'
import { useTheme } from '../../compositions/useTheme'
import { useDeleteDialogCartItem } from './useDeleteDialogCartItem'
import { useCartItemCategory } from '../cart-item/useCartItemCategory'

import type { AS_NonStandardCart } from '../../../../types'

const { $language } = useAppConfigs()
const { actions } = useStore()

const isShow = ref(false)
const item = ref<any>({})

const { deleteDialogThemeVars } = useTheme()
const { constant, language, use, config } = useDeleteDialogCartItem(item)
const { isPresent, isAddBuy } = useCartItemCategory(item)

let analysisInstance: AS_NonStandardCart.AnalysisInstance
const show = (options) => {
  isShow.value = true
  item.value = options.item
  analysisInstance = options.analysisInstance as AS_NonStandardCart.AnalysisInstance

  analysisInstance?.triggerNotice({
    id: 'expose_delete_tips.comp_non-standard-cart',
    data: getExtraData(item.value, 1),
  })
}

const onClickYes = async () => {
  isShow.value = false
  const res = await actions.delCartItemByIds([item.value.id])
  if (res) {
    analysisInstance?.triggerNotice({
      id: 'click_goods_delete.comp_non-standard-cart',
      data: getExtraData(item.value, 1),
    })
  } else {
    analysisInstance?.triggerNotice({
      id: 'click_goods_delete.comp_non-standard-cart',
      data: getExtraData(item.value, 0),
    })
  }
}

const onClickNo = () => {
  isShow.value = false
  analysisInstance?.triggerNotice({
    id: 'click_goods_delete.comp_non-standard-cart',
    data: getExtraData(item.value, 0),
  })
}

defineExpose({
  show,
})

const getExtraData = (item, result = 0) => {
  let items_type = 'normalitem'
  if (isAddBuy.value) {
    items_type = 'additem'
  }
  if (isPresent.value) {
    items_type = 'giftitem'
  }
  return {
    actual_point: '-',
    available_point: '-',
    result,
    items_type,
    save_money: item.single_discount_price?.usdAmountWithSymbol ?? '-',
    sence: 1,
    type_id: (item.promotionTypeIds || []).join() ?? '-',
  }
}
</script>

<style lang="less">
.bsc-nsc-delete-dialog {
  &__title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__item {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ffe8e1;
  }

  &__yes {
    width: 100%;
  }

  &__no {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: #2d68a8 !important;
    background: transparent !important;
    border-color: transparent !important;
  }

  .sui-dialog__wrapper {
    background-image: var(--bsc-nsc-delete-dialog-background-image, url('https://img.ltwebstatic.com/images3_acp/2024/01/27/e5/170636281931439b7068a3bf17e2db8898b50bf7e7.png'));
    background-size: 100% auto, 100% auto;
    background-repeat: no-repeat;
    background-color: #f5f5f5;
  }

  .sui-dialog__closebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;

    .sui-icons.icons-close_alert {
      color: var(--bsc-nsc-delete-dialog-close-icon-color, #fff);
    }
  }

  .sui-dialog__title {
    margin-bottom: 12/37.5rem;
    color: var(--bsc-nsc-delete-dialog-title-color, #fff);
  }

  .sui-dialog__footer {
    padding-bottom: 10/37.5rem;
    background: transparent;
  }
}
</style>
