import { nextTick } from 'vue'
import { template } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { useStore } from '../../store/useStore'
import { getCartItemIsNewUsersBonus } from './useCartItemCategory'

const useCartItemQty = () => {
  const { $language } = useAppConfigs()
  const { actions } = useStore()

  const updateCartItemQty = async ({ item, qty, updateQty }) => {
    const oldQty = item.quantity
    updateQty(qty)
    await nextTick()
    if (getCartItemIsNewUsersBonus(item)) {
      const maxQty = item.aggregateProductBusiness.maxLimitPurchaseQuantity
      if (+qty > +maxQty) {
        SToast(template(item.aggregateProductBusiness.maxLimitPurchaseQuantity, $language.BNonStandardCart.NewUserOverrunMsg))
        updateQty(oldQty)
        return
      }
    }
    if (qty > 99) {
      SToast(template('99', $language.BNonStandardCart.OverrunMsg))
      updateQty(oldQty)
      return
    }
    if (qty > item.realTimeInventory) {
      qty = item.realTimeInventory
      updateQty(qty)
    }
    const cartUpdateParams = {
      cartId: item.id,
      quantity: qty + '',
      isChecked: item.is_checked,
      appendIdList: item.appendIds,
    }

    const res = await actions.updateCartItemQty(cartUpdateParams)
    if (!res) updateQty(oldQty)
  }

  return {
    updateCartItemQty,
  }
}

export {
  useCartItemQty,
}
