import { ref, computed } from 'vue'
import { getConstant } from '@shein-aidc/bs-cart-item-mobile'
import { useStore } from '../../store/useStore'

import type { Mobile } from '@shein-aidc/bs-cart-item-mobile'

const useDeleteDialogCartItem = (item) => {

  const { state, getters } = useStore()

  const isNewCartrowVision = computed(() => getters.isNewCartrowVision.value)
  const priceMode = computed(() => item.value.aggregateProductBusiness?.priceData?.priceMode || 'normal')

  const constant = getConstant()

  const language = ref({})

  const use: Mobile.MainCart[] = [
    'GoodsImg',
    'GoodsDiscountFlag',
    'GoodsBelt',
    'GoodsTitle',
    'GoodsSeriesFlag',
    'GoodsPrice',
    'GoodsSaleAttr',
    'QuickShipTag',
    'FreeShippingTag',
    'SFSTag',
    'EvoluTag',
    'MorePromotionTag',
    'DiscountNormalTag',
    // 'EstimatedPrice',
    'GoodsRepickButton',
    'HolidayDeliveryTag',
    'BundleBuyTag',
  ]


  const config = ref<Mobile.Config<Mobile.MainCart>>({
    GoodsDiscountFlag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if ((priceMode.value !== 'normal' && state.featureConfig.cartItem?.hideDelPrice) || !!state.featureConfig.cartItem?.hideAllDiscount) {
          return {
            isShow: false,
          }
        }
        return {
          isShowMask: false,
        }
      },
    },
    GoodsBelt: {
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    GoodsSeriesFlag: {
      props() {
        return {
          // isShowMask: false,
        }
      },
    },
    QuickShipTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    FreeShippingTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    EvoluTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    SFSTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    MorePromotionTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if (state.featureConfig.cartItem?.hideMorePromotionTag) {
          return {
            isShow: false,
          }
        }
        return {
          isClick: false,
          isShowMask: false,
        }
      },
    },
    GoodsImg: {
      props() {
        return {
          isLazyLoad: false,
          isClick: false,
          isShowMask: false,
        }
      },
    },
    GoodsTitle: {
      props() {
        return {
          isClick: false,
          isShowMask: false,
        }
      },
    },
    GoodsPrice: {
      props() {
        if (state.featureConfig.cartItem?.hideDelPrice) {
          if (priceMode.value !== 'normal') {
            return {
              salePriceColor: '#222',
              isShowOriginalPrice: false,
              isShowOriginalPricePopover: false,
              isShowSuggestPrice: false,
              isShowMask: false,
            }
          }
          return {
            isShowOriginalPrice: false,
            isShowMask: false,
          }
        }
        return {
          isShowMask: false,
        }
      },
    },
    GoodsSaleAttr: {
      props() {
        return {
          isClick: false,
          isShowMask: true,
        }
      },
    },
    DiscountNormalTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        if ((priceMode.value !== 'normal' && state.featureConfig.cartItem?.hideDelPrice) || !!state.featureConfig.cartItem?.hideAllDiscount) {
          return {
            isShow: false,
          }
        }
        return {
          isClick: false,
          isShowMask: false,
        }
      },
    },
    // EstimatedPrice: {
    //   type: !isNewCartrowVision.value ? 'v1' : 'v2',
    //   props() {
    //     if ((priceMode.value !== 'normal' && state.featureConfig.cartItem?.hideDelPrice) || !!state.featureConfig.cartItem?.hideAllDiscount) {
    //       return {
    //         isShow: false,
    //       }
    //     }
    //     return {
    //       isClick: false,
    //       isShowMask: false,
    //     }
    //   },
    // },
    GoodsRepickButton: {
      props() {
        return {
          isShow: false,
        }
      },
    },
    HolidayDeliveryTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
    BundleBuyTag: {
      type: !isNewCartrowVision.value ? 'v1' : 'v2',
      props() {
        return {
          isShowMask: false,
        }
      },
    },
  })

  return {
    constant,
    language,
    use,
    config,
  }
}

export {
  useDeleteDialogCartItem,
}
