import Wing from '@shein/wing'
import { EXTERNAL_COMPATIBLE_BRIDGES } from './constants'

export const initWing = () => {
  Wing.initConfig({
    // 使用平台: 'shein' | 'romwe'
    platform: 'shein',
    /** 兼容配置 **/
    // [兼容] bridge -> event_type 映射
    bridgeToEventTypeMap: {},
    // [兼容] bridge params -> event_type params 映射
    bridgeToEventTypeParamsMap: {},
    // [兼容] event result -> route result 映射
    eventToBridgeResultMap: {},
    // 不需要兼容旧通信的 bridge 列表
    externalCompatibleBridges: EXTERNAL_COMPATIBLE_BRIDGES,
  })
}
