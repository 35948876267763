<template>
  <span
    class="bsc_icon_close_16px"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00011 9.06076L11.7123 12.773L12.773 11.7123L9.06077 8.0001L12.773 4.28788L11.7123 3.22722L8.00011 6.93944L4.28772 3.22705L3.22706 4.28771L6.93945 8.0001L3.22705 11.7125L4.28771 12.7732L8.00011 9.06076Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_close_16px" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: 'inhert',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
})) as any
</script>

<style lang="less">
.bsc_icon_close_16px {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 14px);
  color: var(--color, 'inhert');
}
</style>
