import { computed } from 'vue'

import type { C_NonStandardCartFooter } from '../../../../types'

const useTheme = (props: C_NonStandardCartFooter.IProps) => {
  const themeVars = computed(() => ({
    '--bsc-nsc-footer-background-color': props.customThemeVars?.['--bsc-nsc-footer-background-color'] ?? '#fff',
    '--bsc-nsc-footer-logo-background-image': props.customThemeVars?.['--bsc-nsc-footer-logo-background-image'] ?? 'url(https://img.ltwebstatic.com/images3_ccc/2024/09/04/cb/1725419197c325124fdfee444316719b90c6159aa0.png)',
    '--bsc-nsc-footer-total-discount-price-color': props.customThemeVars?.['--bsc-nsc-footer-total-discount-price-color'] ?? '#fa6338',
    '--bsc-nsc-footer-saved-price-color': props.customThemeVars?.['--bsc-nsc-footer-saved-price-color'] ?? '#767676',
    '--bsc-nsc-footer-checkout-background-color': props.customThemeVars?.['--bsc-nsc-footer-checkout-background-color'] ?? '#000',
    '--bsc-nsc-footer-checkout-color': props.customThemeVars?.['--bsc-nsc-footer-checkout-color'] ?? '#fff',
  }))
  return {
    themeVars,
  }
}

export {
  useTheme,
}
