<template>
  <div
    class="bsc-nsc-content"
    :style="{
      ...contentThemeVars,
      ...cartItemCssVar,
    }"
  >
    <div class="bsc-nsc-content__header">
      <span class="bsc-nsc-content__header-title">
        {{ description.title }}
      </span>
      <sui_icon_doubt_12px_2
        class="bsc-nsc-content__header-doubt"
        @click="toggleDoubtShow"
      />
    </div>
    <template
      v-for="group in groups"
      :key="group.type"
    >
      <CartGroup
        v-if="getGroupShowStatus(group)"
        :disabled="getStickyDisabled(group)"
        :sticky-top="HEADER_HEIGHT"
        class="bsc-nsc-content__group"
        :class="{
          'is-inapplicable': group.type === 'inapplicable',
        }"
      >
        <template #header="{ isSticky }">
          <div
            class="bsc-nsc-content__group-header"
            :class="{
              'is-sticky': isSticky,
              'is-applicable': group.type === 'applicable',
              'is-empty': !group.content.length,
            }"
          >
            <div class="bsc-nsc-content__group-header2">
              <div class="bsc-nsc-content__group-header3">
                <SCheckbox
                  :model-value="getIsChecked(group)"
                  :gap="checkboxGap"
                  :disabled="getCheckedDisabled(group)"
                  @click="onClickGroupChecked(group)"
                />
                <div class="bsc-nsc-content__group-title">
                  <span class="bsc-nsc-content__group-title-text">{{ getGroupHeaderTitle(group) }}</span>
                  <span class="bsc-nsc-content__group_title-qty">({{ group.header.number }})</span>
                </div>
                <div
                  v-if="isShowTotalPrice"
                  class="bsc-nsc-content__group-price"
                >
                  {{ group.header.totalPrice }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #default>
          <div class="bsc-nsc-content__group-body">
            <template v-if="group.content.length">
              <TransitionGroup :name="deleteTransitionName">
                <template
                  v-for="(item, index) in group.content"
                  :key="item.id"
                >
                  <CartItem
                    :item="item"
                    :index="index"
                  />
                </template>
              </TransitionGroup>
            </template>
            <template v-else>
              <CartEmpty
                :group="group"
                @back-to-add="onBackToAdd"
              />
            </template>
          </div>
        </template>
      </CartGroup>
    </template>

    <SLazyMount>
      <SDialog
        v-model:visible="doubtShow"
        append-to-body
        show-close
      >
        <template #title>
          {{ description.questionMark }}
        </template>
        <template #footer>
          <SButton
            :type="['primary', 'H72PX']"
            @click="toggleDoubtShow"
          >
            {{ $language.BNonStandardCart.OK }}
          </SButton>
        </template>
      </SDialog>
    </SLazyMount>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { useStore } from '../../store/useStore'
import CartGroup from '../../layout/CartGroup.vue'
import CartItem from '../cart-item/CartItem.vue'
import CartEmpty from '../CartEmpty.vue'
import sui_icon_doubt_12px_2 from '../icons/sui_icon_doubt_12px_2.vue'
import { useTheme } from '../../compositions/useTheme'
import { useCartItemCssVar } from '../cart-item/useCartItemCssVar'
import { useGroupCheckbox } from './useGroupCheckbox'

const HEADER_HEIGHT = 40

const emit = defineEmits(['backToAdd'])

const { $language } = useAppConfigs()

const { state, getters } = useStore()

const { contentThemeVars } = useTheme()
const { cartItemCssVar } = useCartItemCssVar()
const { getIsChecked, getCheckedDisabled, onClickGroupChecked } = useGroupCheckbox()

const deleteTransitionName = computed(() => state.featureConfig.disabledDeleteAnimation ? '' : 'bsc-nsc-content_delete')
const checkboxGap = computed(() => getters.checkboxGap.value)
const description = computed<any>(() => state.description)
const groups = computed<any>(() => state.groups)
const isShowTotalPrice = computed(() => !state.featureConfig.group?.hideTotalPrice)

const getGroupShowStatus = (group: any) => {
  if (group.type === 'inapplicable') {
    return !!group.content.length
  }
  return true
}

const getGroupHeaderTitle = (group: any) => {
  let title = group.header.title
  if (group.type === 'applicable') {
    title = state.featureConfig.group?.applicableHeaderTitle || title
  }
  return title
}

const getStickyDisabled = (group: any) => {
  return !group.content?.length
}

const doubtShow = ref(false)
const toggleDoubtShow = () => {
  doubtShow.value = !doubtShow.value
}

const onBackToAdd = () => {
  emit('backToAdd')
}
</script>

<style lang="less">
@header-height: 40px;
@radius: 12px;
@body-bg: #fff;
@margin: 8px;

.bsc-nsc-content {
  &__header {
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 16px 8px;
    height: @header-height;
    line-height: 1;
    font-size: 16px;
    font-weight: 800;
    color: var(--bsc-nsc-header-title-color);
    text-align: center;
    background-image: var(--bsc-nsc-background-image);
    background-size: 100% 326/37.5rem;
    background-repeat: no-repeat;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__header-title {
    max-width: 70%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__header-doubt {
    margin-left: 6px;
    color: var(--bsc-nsc-header-title-doubt-color) !important;
  }

  &__group {
    margin: @radius + 10px @margin;
    margin-top: 0;

    --bsc-nsc-group-sticky-top: @header-height;
  }

  &__group-header {
    background-size: 100%;
    background-position: 0 -@header-height;

    &.is-sticky {
      background-image: var(--bsc-nsc-background-image);
      background-size: calc(100% + @margin * 2) 326/37.5rem;
      background-position: left -@margin top -@header-height;
      background-repeat: no-repeat;
      overflow: hidden;
    }

    &.is-applicable {
      .bsc-nsc-content__group-header3 {
        background: var(--bsc-nsc-applicable-group-header-background);
      }
    }

    &.is-applicable:not(.is-empty) {
      .bsc-nsc-content__group-title {
        color: #fa6338;
      }
    }
  }

  &__group-header2 {
    background-color: #fff;
    border-top-left-radius: @radius;
    border-top-right-radius: @radius;
  }

  &__group-header3 {
    display: flex;
    align-items: center;
    padding: var(--item-gap);
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    background-color: #fff;
    border-top-left-radius: @radius;
    border-top-right-radius: @radius;

    .sui-checkbox__label-text {
      padding-left: 0;
    }
  }

  &__group-title {
    display: inline-flex;
    min-width: 0;
  }

  &__group-title-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__group-price {
    margin-left: auto;
    padding-left: 12px;
  }

  &__group-body {
    margin-top: -1px;
    background-color: @body-bg;

    &::after {
      content: '';
      position: absolute;
      bottom: -@radius + 1px;
      left: 0;
      right: 0;
      width: 100%;
      height: @radius;
      background-color: @body-bg;
      border-bottom-left-radius: @radius;
      border-bottom-right-radius: @radius;
    }
  }
}

.bsc-nsc-content_delete-move,
.bsc-nsc-content_delete-leave-active {
  transition: transform .3s linear, opacity .3s ease;
}

.bsc-nsc-content_delete-leave-to {
  transform: translateY(-100%);
  opacity: 0;
  z-index: 0;
}

/* 确保将离开的元素从布局流中删除
  以便能够正确地计算移动的动画。 */
.bsc-nsc-content_delete-leave-active {
  position: absolute;
}
</style>
