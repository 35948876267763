<template>
  <SwipeCell
    v-expose="{
      id: 'expose_goods_list.comp_non-standard-cart',
      data: {
        goodsId: item.product?.goods_id,
        skuId: item.product?.goods_sn,
        spuId: item.product?.productRelationID,
        index,
        usPrice: item.unitPrice?.usdAmount,
        usOriginPrice: item.product?.retailPrice?.usdAmount,
        attrStock: item.attr?.stock,
        insurable: item.product?.insurable,
        unuseCouponTips: 'unused_coupon_tip_0',
        tspId: '',
        mallCode: item.mall_code,
        showEvoluShein,
        priceTagText,
      },
    }"
    :style="itemStyle"
    :class="['bsc-nsc-cart-item', `j-bsc-nsc-cart-item-${item.id}`]"
    @open="onOpen"
    @close="onClose"
  >
    <template #default>
      <div
        class="bsc-nsc-cart-item__wrap"
        :class="{
          'bsc-nsc-cart-item__wrap_disabled': disabled
        }"
      >
        <div
          class="bsc-nsc-cart-item__checkbox"
          @click="onClickCheckbox"
        >
          <SCheckbox
            v-model="isChecked"
            :disabled="checkboxDisabled"
            :gap="checkboxGap"
          />
        </div>
        <BMainCartItem
          :key="item.id"
          :constant="constant"
          :language="language"
          :use="use"
          :data="item"
          :config="config"
          class="bsc-nsc-cart-item__content"
        />
      </div>
    </template>
    <template #right>
      <div class="bsc-nsc-cart-item__operate">
        <div
          class="bsc-nsc-cart-item__operate-delete"
          @click="onClickDelete"
        >
          {{ $language.BNonStandardCart.Delete }}
        </div>
      </div>
    </template>
  </SwipeCell>
</template>

<script lang="ts" setup>
import { ref, computed, inject } from 'vue'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { BMainCartItem } from '@shein-aidc/bs-cart-item-mobile'
import { SwipeCell } from '@shein-aidc/bs-cart-components-mobile'
import { SCheckbox } from '@shein-aidc/sui-checkbox/mobile'
import { useStore } from '../../store/useStore'
import { useCartItem } from './useCartItem'
import { useCartItemCheckbox } from './useCartItemCheckbox'
import { useDeleteDialog } from '../delete-dialog/useDeleteDialog'

import type { AS_NonStandardCart } from '../../../../types'

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
})

const { $language } = useAppConfigs()
const { state, getters } = useStore()

const cartItem = computed(() => props.item)

const { constant, language, use, config, isAdditionGoodsView } = useCartItem(cartItem, props.index)
const { isChecked, checkboxDisabled, onClickCheckbox } = useCartItemCheckbox(cartItem)
const { showCartDeleteDialog } = useDeleteDialog()

const disabled = ref(false)
const onOpen = () => {
  disabled.value = true
  const targetElement = document.querySelector(`${state.dropdownBoxBodyClass} .bsc-cart-dropdown-box__content`)
  if (!targetElement) return
  targetElement.dispatchEvent(new Event('scroll'))
}
const onClose = () => {
  disabled.value = false
}

const checkboxGap = computed(() => getters.checkboxGap.value)
const showEvoluShein = computed(() => !!props.item.aggregateProductBusiness?.productTags?.find(tag => tag.type === 'evoluShein'))

// 是否调整购物车主图尺寸并展示新的商品行标签样式（UG-101110）
const itemStyle = computed(() => ({
  '--template-background-color': isAdditionGoodsView.value && '#fffbee',
}))

const priceTagText = computed(() => {
  const tag = props.item.aggregateProductBusiness?.priceData?.priceTags?.filter?.(v => v.show == '1')?.[0]
  return tag?.type
})

const onClickDelete = () => {
  analysisInstance?.triggerNotice({
    id: 'click_goods_del_button.comp_non-standard-cart',
    data: {
      goods_id: props.item.product?.goods_id ?? '-',
      cart_id: props.item.id ?? '-',
    },
  })
  showCartDeleteDialog({ item: props.item })
}

// 埋点源:
const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')
const { vExpose } = analysisInstance || {}
// 埋点源: END
</script>

<style lang="less">
.bsc-nsc-cart-item {
  position: relative;
  z-index: 1;
  background: var(--template-background-color, #fff);
  width: 100%;

  &:last-child {
    .bsc-nsc-cart-item__wrap {
      padding-bottom: 0;
    }
  }

  &__wrap {
    display: flex;
    padding: 20/75rem var(--item-gap, 0.2667rem);
    width: 100%;
  }

  &__wrap_disabled {
    pointer-events: none;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    height: var(--checkbox-height, 1.973rem);

    .sui-checkbox__label-text {
      padding-left: 0;
    }
  }

  &__content {
    flex: 1;
    min-width: 0;
  }

  &__operate {
    display: flex;
    height: 100%;
  }

  &__operate-delete {
    display: flex;
    align-items: center;
    padding: 0 12px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #bf4123;
  }
}
</style>
