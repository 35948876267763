import { state } from './state'
import { getGetters } from './getters'
import { getActions } from './actions'

export const useStore = () => {
  const getters = getGetters({ state })

  const actions = getActions({ state, getters })

  return {
    state,
    getters,
    actions,
  }
}
