import { ref, computed } from 'vue'
import { useStore } from '../../store/useStore'
import { useTouch } from '../../compositions/useTouch'
import { useEventListener } from '../../compositions/useEventListener'

import type { CSSProperties } from 'vue'

const useTouchStyle = (rootRef) => {
  const { state } = useStore()
  const dragging = ref(false)
  const touchState = ref({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const boundary = ref({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })
  const touchStyle = computed(() => {
    const style: CSSProperties = {}
    const x = `${touchState.value.x}px`
    const y = `${touchState.value.y}px`
    style.transform = `translate3d(${x}, ${y}, 0)`
    if (dragging.value) {
      style.transition = 'none'
    }
    return style
  })

  const touch = useTouch()

  let prevX = 0
  let prevY = 0
  const onTouchStart = (e: TouchEvent) => {
    touch.start(e)
    dragging.value = true
    prevX = touchState.value.x
    prevY = touchState.value.y
  }
  const onTouchMove = (e: TouchEvent) => {
    touch.move(e)
    e.preventDefault()

    let nextX = prevX + touch.deltaX.value
    if (nextX < boundary.value.left) nextX = boundary.value.left
    if (nextX > boundary.value.right) nextX = boundary.value.right
    touchState.value.x = nextX

    let nextY = prevY + touch.deltaY.value
    if (nextY < boundary.value.top) nextY = boundary.value.top
    if (nextY > boundary.value.bottom) nextY = boundary.value.bottom
    touchState.value.y = nextY
  }
  const onTouchEnd = () => {
    dragging.value = false
  }

  useEventListener('touchmove', onTouchMove, {
    target: rootRef,
  })

  const updateTouchState = () => {
    const { width, height, top } = rootRef.value.getBoundingClientRect()
    const rect = document.querySelector(`${state.dropdownBoxBodyClass} .bsc-nsc-content__header`)?.getBoundingClientRect()
    touchState.value = {
      x: touchState.value.x,
      y: touchState.value.y,
      width,
      height,
    }
    boundary.value = {
      top: boundary.value.top || -(top - rect!.bottom),
      right: 0,
      bottom: 0,
      left: -(window.innerWidth - width - 16),
    }
  }

  return {
    touchStyle,
    updateTouchState,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  }
}

export {
  useTouchStyle,
}
