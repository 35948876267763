<template>
  <slot></slot>
</template>

<script lang="ts" setup>
import { provide } from 'vue'

const props = defineProps({
  accordion: {
    type: Boolean,
    default: false,
  },
})

// 通过 provide 收集所有的 swipe-cell 组件
const swipeCellMap = new Map()
provide('swipeCell', {
  register: (key, value) => {
    swipeCellMap.set(key, value)
  },
  unregister: key => {
    swipeCellMap.delete(key)
  },
  notify: (key, value) => {
    if (!props.accordion) return
    for (const [_key, _value] of swipeCellMap) {
      if (_key !== key) {
        _value.close(value)
      }
    }
  },
})

const closeAll = () => {
  for (const [, _value] of swipeCellMap) {
    _value.close()
  }
}

defineExpose({
  closeAll,
})
</script>
