import { ref, computed, inject } from 'vue'
import { debounce } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { useStore } from '../../store/useStore'
import { useCartItemCategory } from './useCartItemCategory'

import type { AS_NonStandardCart } from '../../../../types'

const useCartItemCheckbox = (item) => {
  const { $language } = useAppConfigs()
  const { actions, state, getters } = useStore()
  const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')

  const { isPresent, isAddBuy } = useCartItemCategory(item)

  const checkboxDisabled = computed(() => isPresent.value || isAddBuy.value)

  const _isChecked = ref<boolean | null>(null)
  const isChecked = computed({
    get() {
      return typeof _isChecked.value === 'boolean' ? _isChecked.value : item.value.is_checked === '1'
    },
    set(val) {
      const normalCheckIdList = Array.from(new Set([item.value.id, ...item.value.appendIds])).filter((id) =>
        getters.cartIds.value.includes(id),
      )
      if (state.featureConfig.requireAtLeastOne && !val && getters.checkedCartListNum.value <= normalCheckIdList.length) {
        SToast($language.BNonStandardCart.RequireAtLeastOneMsg)
        return false
      }
      _isChecked.value = val
      onChangeCheckbox(val)
    },
  })

  const onChangeCheckbox = debounce({
    func: async (val) => {
      try {
        const cartUpdateParams = {
          cartIdList: [item.value.id],
          operationType: val ? '1' : '2',
          appendIdList: item.value.appendIds,
        }

        analysisInstance?.triggerNotice({
          id: 'click_select_someproduct.comp_non-standard-cart',
          data: {
            select_type: 1,
            is_select: val ? 1 : 0,
            goods_sn: item.value.product?.goods_sn ?? '-',
          },
        })

        await actions.updateCartItemCheckbox(cartUpdateParams)
      } finally {
        _isChecked.value = null
      }
    },
    wait: 200,
  })

  const onClickCheckbox = () => {
    if (isPresent.value) {
      // 若是尝试取消, 则 toast 提示
      return isChecked.value && SToast($language.BNonStandardCart.FreeGiftEditMsg)
    }
    if (isAddBuy.value) {
      // 若是尝试取消, 则 toast 提示
      return isChecked.value && SToast($language.BNonStandardCart.AddonItemEditMsg)
    }
  }

  return {
    isChecked,
    checkboxDisabled,
    onClickCheckbox,
  }
}

export {
  useCartItemCheckbox,
}
