<template>
  <span
    class="bsc_icon_doubt_12px_2"
    :style="styles"
    @click="$emit('click')"
  >
    <svg
      style="width: auto; height: 1em"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 6C11.5 2.96243 9.03757 0.5 6 0.5C2.96243 0.5 0.5 2.96243 0.5 6C0.5 9.03757 2.96243 11.5 6 11.5C9.03757 11.5 11.5 9.03757 11.5 6ZM1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6ZM6.31996 9.13495L6.3195 8.13455H5.32L5.31996 9.13495H6.31996ZM7.08866 6.48411C7.97998 5.90939 8.27223 4.72992 7.73614 3.80139C7.1833 2.84384 5.95889 2.51576 5.00134 3.0686C4.35718 3.44051 3.99789 4.11633 4.00001 4.81038L5.00019 4.81044C4.99771 4.46165 5.17768 4.12149 5.50134 3.93463C5.9806 3.65793 6.59342 3.82213 6.87012 4.30139C7.14682 4.78065 6.98261 5.39347 6.50336 5.67017L6.39931 5.73382C5.81851 6.10986 5.43906 6.71402 5.34229 7.38475L6.35759 7.3848C6.44462 7.03978 6.673 6.72692 7.00336 6.53619L7.08866 6.48411Z"
        fill="currentColor"
      />
    </svg>
  </span>
</template>

<script name="sui_icon_doubt_12px_2" setup lang="ts">
import { computed } from 'vue'

defineEmits(['click'])

const props = defineProps({
  size: {
    type: String,
    default: '16px',
  },
  color: {
    type: String,
    default: 'inhert',
  },
})

const styles = computed(() => ({
  '--font-size': props.size,
})) as any
</script>

<style lang="less">
.bsc_icon_doubt_12px_2 {
  display: inline-block;
  line-height: 0;
  vertical-align: middle;
  font-size: var(--font-size, 16px);
  color: var(--color, 'inhert');
}
</style>
