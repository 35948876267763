<template>
  <div class="bsc-nsc-cart-empty">
    <SFeedback :content="tipText">
      <template #icon>
        <img
          src="https://img.ltwebstatic.com/images3_acp/2024/01/27/8b/1706362838fb2d539d5976fbe9c13e3abca218478d.png"
          class="bsc-nsc-cart-empty__image"
        />
      </template>
      <template #footer>
        <SButton
          v-expose="{
            id: 'expose_gobacktoadd.comp_non-standard-cart'
          }"
          :type="['primary', 'H48PX']"
          @click="onClickBackToAdd"
        >
          {{ buttonText }}
        </SButton>
      </template>
    </SFeedback>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import { SFeedback } from '@shein-aidc/sui-feedback/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'

import type { AS_NonStandardCart } from '../../../types'

const emit = defineEmits(['backToAdd'])

const props = defineProps({
  group: {
    type: Object,
    required: true,
  },
})

const tipText = computed(() => {
  return props.group.groupInfo?.emptyGroupTip?.tipText || ''
})

const buttonText = computed(() => {
  return props.group.groupInfo?.emptyGroupTip?.buttonText || ''
})

const onClickBackToAdd = () => {
  analysisInstance?.triggerNotice({
    id: 'click_gobacktoadd.comp_non-standard-cart',
  })

  emit('backToAdd')
}

// 埋点源:
const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')
const { vExpose } = analysisInstance || {}
// 埋点源: END
</script>

<style lang="less">
.bsc-nsc-cart-empty {
  &__image {
    width: 75px;
    height: 75px;
  }

  .sui-feedback {
    padding: 0 0 12px 0;
  }

  .sui-feedback__title {
    display: none;
  }

  .sui-feedback__content {
    margin-bottom: 10px;
  }
}
</style>
