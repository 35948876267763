<template>
  <div
    v-show="show"
    ref="rootRef"
    v-expose="{
      id: 'expose_backtop.comp_non-standard-cart',
    }"
    class="bsc-nsc-back-top"
    :style="touchStyle"
    @click="onClickBackTop"
    @touchstart-passive="onTouchStart"
    @touchend="onTouchEnd"
    @touchcancel="onTouchEnd"
  >
    <img src="https://img.ltwebstatic.com/images3_acp/2024/02/05/cf/1707128213f507bceda2d495c34ae17dd2d4e00b54.png" />
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted, nextTick, inject } from 'vue'
import { throttle } from '@shein/common-function'
import { useStore } from '../../store/useStore'
import { useEventListener } from '../../compositions/useEventListener'
import { useTouchStyle } from './useTouchStyle'

import type { AS_NonStandardCart } from '../../../../types'

const { state } = useStore()

const show = ref(false)

const onScroll = throttle({
  func: () => {
    if (!scrollDom.value) return show.value = false
    if (!targetDom.value) targetDom.value = document.querySelector(`${state.dropdownBoxBodyClass} .bsc-nsc-content__group.is-inapplicable .bsc-nsc-group__sentinel`)
    if (!targetDom.value || !targetDom.value.offsetParent) return show.value = false
    const targetRect = targetDom.value.getBoundingClientRect()
    const scrollRect = scrollDom.value.getBoundingClientRect()
    show.value = targetRect.top < scrollRect.top
  },
  wait: 500,
})

const scrollDom = ref()
const targetDom = ref()
useEventListener('scroll', onScroll, {
  target: scrollDom,
  passive: true,
})

onMounted(() => {
  scrollDom.value = document.querySelector(`${state.dropdownBoxBodyClass} .bsc-cart-dropdown-box__content`)
})

onUnmounted(() => {
  scrollDom.value = null
})

const onClickBackTop = () => {
  analysisInstance?.triggerNotice({
    id: 'click_backtop.comp_non-standard-cart',
  })
  scrollDom.value?.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
}

const rootRef = ref()
const {
  touchStyle,
  onTouchStart,
  onTouchEnd,
  updateTouchState,
} = useTouchStyle(rootRef)

watch(show, () => {
  if (!show.value) return
  nextTick(() => {
    updateTouchState()
  })
})

// 埋点源:
const analysisInstance = inject<AS_NonStandardCart.AnalysisInstance>('analysisInstance')
const { vExpose } = analysisInstance || {}
// 埋点源: END
</script>

<style lang="less">
.bsc-nsc-back-top {
  position: absolute;
  z-index: 100;
  right: 8px;
  bottom: 8px;
  width: 44px;
  height: 44px;
  border-radius: 50%;

  > img {
    width: 100%;
    height: 100%;
  }
}
</style>
