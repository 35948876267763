import { computed } from 'vue'
import { useStore } from '../store/useStore'

import type { C_NonStandardCart } from '../../../types'

const defaultThemeVars: C_NonStandardCart.CustomThemeVars = {
  '--bsc-nsc-background-color': '#f5f5f5',
  '--bsc-nsc-close-icon-color': '#fff',
  '--bsc-nsc-header-title-color': '#fffdd8',
  '--bsc-nsc-header-title-doubt-color': 'rgba(255, 255, 255, 0.6)',
  '--bsc-nsc-applicable-group-header-background': 'linear-gradient(183deg, #FFF4CD 2.79%, #FFF 59.78%)',
  '--bsc-nsc-delete-dialog-title-color': '#fff',
  '--bsc-nsc-delete-dialog-close-icon-color': '#fff',
}

export const useTheme = () => {
  const { state, getters } = useStore()
  const themeVars = computed<C_NonStandardCart.CustomThemeVars>(() => ({
    ...defaultThemeVars,
    ...getters.remoteThemeVars.value,
    ...state.customThemeVars,
  }))

  const dropdownBoxBodyStyle = computed(() => ({
    '--bsc-cart-dropdown-box-close-icon-color': themeVars.value['--bsc-nsc-close-icon-color'],
  }))
  const dropdownBoxContentStyle = computed(() => ({
    '--bsc-cart-dropdown-box-background-color': themeVars.value['--bsc-nsc-background-color'],
    backgroundImage: themeVars.value['--bsc-nsc-background-image'],
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  }))

  const contentThemeVars = computed(() => ({
    ...themeVars.value, // TODO 剔除不需要的 css vars
  }))

  const deleteDialogThemeVars = computed(() => ({
    '--bsc-nsc-delete-dialog-background-image': themeVars.value['--bsc-nsc-delete-dialog-background-image'],
    '--bsc-nsc-delete-dialog-title-color': themeVars.value['--bsc-nsc-delete-dialog-title-color'],
    '--bsc-nsc-delete-dialog-close-icon-color': themeVars.value['--bsc-nsc-delete-dialog-close-icon-color'],
  }))

  return {
    dropdownBoxBodyStyle,
    dropdownBoxContentStyle,
    contentThemeVars,
    deleteDialogThemeVars,
  }
}
