import * as standard from './sources/standard'
import { organizeDataSource, createFsDataRunnerBySource } from '@shein-aidc/utils-data-source-toolkit'
import type { DS_NonStandardCart } from '../../types'

export function getSource({ dataSource = {}, appConfigs }: DS_NonStandardCart.SourceParams): DS_NonStandardCart.APIS {
  return organizeDataSource<DS_NonStandardCart.APIS, DS_NonStandardCart.DataSource>(
    {
      standard,
    },
    dataSource,
    appConfigs,
  )
}

export function getFsDataRunner(sourceParams: DS_NonStandardCart.FS_SourceParams) {
  const { fetchAbt } = getSource(sourceParams)
  return createFsDataRunnerBySource<DS_NonStandardCart.FS_APIS, DS_NonStandardCart.FS_DATA>({
    fetchAbt,
  })
}
