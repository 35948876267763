import { computed } from 'vue'

// 是否是赠品 (包括付费会员赠品)
const getCartItemIsPresent = item => item.aggregateProductBusiness?.isPresent === '1' || item.isPayMemberGift === '1'
// 是否是加价购附属品
const getCartItemIsAddBuy = item => item.aggregateProductBusiness?.isAddBuy === '1'
// 是否是 APP 新人专享价
const getCartItemIsNewUsersBonus = item => item.aggregateProductBusiness?.showNewUsersBonus === '1'
// 是否是定制商品
const getCartItemIsCustomGoods = item => item.aggregateProductBusiness?.customizationPopInfo?.customContent &&
  item.aggregateProductBusiness?.customizationPopInfo?.effects &&
  item.aggregateProductBusiness?.customizationPopInfo?.images &&
  item.aggregateProductBusiness?.customizationPopInfo?.texts
// 是否是范围组的赠品或者加价购品
const getIsAdditionGoodsView = item => !!item.aggregateProductBusiness?.cartRowViewControl?.additionGoodsView

const getCartItemCategory = (item) => {
  return {
    isPresent: getCartItemIsPresent(item),
    isAddBuy: getCartItemIsAddBuy(item),
    isNewUsersBonus: getCartItemIsNewUsersBonus(item),
    isCustomGoods: getCartItemIsCustomGoods(item),
    isAdditionGoodsView: getIsAdditionGoodsView(item),
  }
}

const useCartItemCategory = (item) => {
  const isPresent = computed(() => getCartItemIsPresent(item.value))
  const isAddBuy = computed(() => getCartItemIsAddBuy(item.value))
  const isNewUsersBonus = computed(() => getCartItemIsNewUsersBonus(item.value))
  const isCustomGoods = computed(() => getCartItemIsCustomGoods(item.value))
  const isAdditionGoodsView = computed(() => getIsAdditionGoodsView(item.value))

  return {
    isPresent,
    isAddBuy,
    isNewUsersBonus,
    isCustomGoods,
    isAdditionGoodsView,
  }
}

export {
  useCartItemCategory,

  getCartItemIsPresent,
  getCartItemIsAddBuy,
  getCartItemIsNewUsersBonus,
  getCartItemIsCustomGoods,
  getIsAdditionGoodsView,

  getCartItemCategory,
}
