<template>
  <div
    ref="rootRef"
    class="bsc-cart-dropdown-box"
    :class="{ 'bsc-cart-dropdown-box_fixed': fixed }"
  >
    <Teleport
      :to="teleport || undefined"
      :disabled="!teleport"
    >
      <div
        v-show="state.showWrapper"
        ref="drawerContentRef"
        class="bsc-cart-dropdown-box__container"
        :style="containerStyle"
      >
        <Transition name="fade">
          <div
            v-show="state.showPopup"
            class="bsc-cart-dropdown-box__overlay"
            @click="onClickOverlay"
            @touchmove.prevent>
          </div>
        </Transition>
        <Transition
          name="slide-up"
          @after-enter="onAfterEnter"
          @after-leave="onAfterLeave"
        >
          <div
            v-show="state.showPopup"
            ref="drawerContent"
            class="bsc-cart-dropdown-box__body"
            :class="bodyClass"
            :style="bodyStyle"
          >
            <div class="bsc-cart-dropdown-box__top">
              <slot name="top"></slot>
            </div>
            <div
              :class="{
                'bsc-cart-dropdown-box__content': true,
                'bsc-cart-dropdown-box__content_round': round,
                'bsc-cart-dropdown-box__overscroll-behavior': !disabledOverscrollBehavior,
              }"
              :style="{
                ...contentStyle,
                ..._contentStyle,
              }"
            >
              <slot :max-height="_contentStyle.maxHeight"></slot>
            </div>
            <div class="bsc-cart-dropdown-box__bottom">
              <slot name="bottom"></slot>
            </div>

            <div
              v-if="closeable"
              class="bsc-cart-dropdown-box__close"
            >
              <sui_icon_close_16px
                size="18px"
                @click="onClickCloseIcon"
              />
            </div>
          </div>
        </Transition>
      </div>
    </Teleport>
    <div
      ref="drawerFooterRef"
      class="bsc-cart-dropdown-box__footer"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, nextTick } from 'vue'
import sui_icon_close_16px from '../components/icons/sui_icon_close_16px.vue'
import { useLockScroll } from '../compositions/useLockScroll'

const props = defineProps({
  maxHeight: {
    type: [Number, String],
    default: 80,
  },
  teleport: {
    type: String,
    default: '',
  },
  closeOnClickOverlay: {
    type: Boolean,
    default: true,
  },
  closeable: {
    type: Boolean,
    default: false,
  },
  fixed: {
    type: Boolean,
    default: true,
  },
  round: {
    type: Boolean,
    default: true,
  },
  disabledOverscrollBehavior: {
    type: Boolean,
    default: false,
  },
  lockScroll: {
    type: Boolean,
    default: true,
  },
  bodyClass: {
    type: [String, Array],
    default: null,
  },
  bodyStyle: {
    type: Object,
    default: null,
  },
  contentStyle: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['open', 'opened', 'close', 'closed', 'clickOverlay', 'clickCloseIcon'])

const getNextIndex = () => {
  if (typeof window === 'undefined') return '100000'
  if (!window.__SUI__INDEX__) window.__SUI__INDEX__ = 99999
  return String(++window.__SUI__INDEX__)
}

const drawerFooterRef = ref()
const drawerContentRef = ref()
const containerStyle = ref<any>({})
const _contentStyle = ref<any>({})
const animationStyle = ref({})
const updateOffset = () => {
  if (drawerFooterRef.value) {
    const { top, height, bottom } = drawerFooterRef.value.getBoundingClientRect()
    const index = getNextIndex()
    containerStyle.value = {
      'bottom': `${window.innerHeight - top}px`,
      zIndex: index,
      transform: `translate3d(0px, 0px, ${index}px)`,
    }
    _contentStyle.value = {
      maxHeight: bottom * +props.maxHeight / 100 - height + 'px',
    }
  }
}
const calcOffset = async () => {
  if (drawerFooterRef.value) {
    const { top, height, bottom } = drawerFooterRef.value.getBoundingClientRect()
    containerStyle.value.bottom = `${window.innerHeight - top}px`
    _contentStyle.value.maxHeight = bottom * +props.maxHeight / 100 - height + 'px'
  }
}

const state = reactive({
  showPopup: false,
  showWrapper: false,
})
const toggle = (show = !state.showPopup) => {
  state.showPopup = show
  if (show) {
    updateOffset()
    state.showWrapper = show
    nextTick(() => {
      const { height, top } = drawerContentRef.value.getBoundingClientRect()
      animationStyle.value = {
        bottom: height + 'px',
        maxHeight: top + 'px',
      }
    })
  }
}
const open = () => {
  toggle(true)
  emit('open')
}
const close = () => {
  toggle(false)
  emit('close')
}
const onClickOverlay = (event: MouseEvent) => {
  if (props.closeOnClickOverlay) {
    emit('clickOverlay', event)
    close()
  }
}
const onClickCloseIcon = (event: MouseEvent) => {
  emit('clickCloseIcon', event)
  close()
}
let timer: ReturnType<typeof setTimeout> | null
const onOpened = () => {
  if (timer) clearTimeout(timer)
  timer = setTimeout(() => {
    emit('opened')
  })
}
const onAfterEnter = () => {
  onOpened()
}
const onAfterLeave = () => {
  state.showWrapper = false
  emit('closed')
}

const rootRef = ref<HTMLElement>()
useLockScroll(rootRef, () => props.lockScroll && state.showWrapper)

defineExpose({
  toggle,
  open,
  close,
  calcOffset,
  updateOffset,
})
</script>

<style lang="less">
@import url('../style/animation.less');

:root,
:host {
  --bsc-cart-dropdown-box-round-radius: 8px 8px 0 0;
  --bsc-cart-dropdown-box-background-color: #fff;
}

.bsc-cart-overflow-hidden {
  overflow: hidden;
}

.bsc-cart-dropdown-box {
  &_fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &__body {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__top {
    position: absolute;
    transform: translateY(-100%);
  }

  &__content {
    position: relative;
    overflow-y: auto;
    overscroll-behavior: none;
    background-color: var(--bsc-cart-dropdown-box-background-color);
  }

  &__content_round {
    border-radius: var(--bsc-cart-dropdown-box-round-radius);
  }

  &__overscroll-behavior {
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }

  &__bottom {
    position: relative;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    font-size: 0;
    color: var(--bsc-cart-dropdown-box-close-icon-color, #999);
    z-index: 10;
  }
}
</style>
