import { SToast } from '@shein-aidc/sui-toast/mobile'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import useApis from '../api'
import { useBridge } from '../bridge/useBridge'
import { POS_KEYS, UPDATE_CART_INFO_ACTIONS } from '../utils/constants'
import { initOverLimitState, showOverLimitToast } from '../utils/overLimit'

import type { State } from './state'

// eslint-disable-next-line max-lines-per-function
export const getActions = ({ state, getters }: { state: State, getters: any }) => {
  const apis = useApis()
  const { getCartPreAddress, getUserLocalSizeCountry } = useBridge()
  const { $language } = useAppConfigs()

  const initAbtInfo = async () => {
    const res = await apis.fetchAbt({ posKeys: POS_KEYS.join(',') })
    if (res.code == 0) {
      state.abtInfo = res.info?.pos || {}
    }
  }

  const initLanguages = async () => {
    await apis.fetchLanguages()
  }

  const initCartPreAddress = async () => {
    const res = await getCartPreAddress()
    state.preAddressInfo = res
  }

  const initUserLocalSizeCountry = async () => {
    const res = await getUserLocalSizeCountry()
    state.userLocalSizeCountry = res
  }

  let isInit = false
  const init = async () => {
    const task = [initUserLocalSizeCountry()]
    if (!isInit) {
      task.push(
        ...[
          initLanguages(),
          initAbtInfo(),
          initCartPreAddress(),
        ],
      )
    }
    await Promise.all(task)
    isInit = true
  }

  const getCommonParams = () => {
    const otherParams: any = {}
    // cart_best_coupon_price 为 on 且为 PWA 券凑单时，自动用券、自动用积分不取业务传入
    if (getters.isCartBestCouponPrice.value && state.customFetchParams.sceneData?.scene === 'couponAddItemPopup') {
      otherParams.autoUseCoupon = getters.isAutoUseCoupon ? '1' : '0'
      otherParams.autoUsePoint = getters.isAutoUsePoint.value ? '1' : '0'
    }

    return {
      cartParams: {
        ...state.preAddressInfo,
        userLocalSizeCountry: state.userLocalSizeCountry,
        ...state.defaultFetchParams.cartParams,
        ...state.customFetchParams.cartParams,
        ...otherParams,
      },
      sceneData: {
        ...state.defaultFetchParams.sceneData,
        ...state.customFetchParams.sceneData,
      },
    }
  }

  const getEntranceGuide = async () => {
    const commonParams = getCommonParams()
    const res = await apis.getEntranceGuide({
      country_id: commonParams.cartParams.countryId,
      auto_use_coupon: commonParams.cartParams.autoUseCoupon,
    })
    if (res.code === '0') {
      state.isFreeShipping = res.info?.is_free_shipping === '1'
    } else {
      state.isFreeShipping = false
    }
  }

  const updateShoppingInfo = async (action, res) => {
    if (res.code === '0') {
      let info = res.info
      if (state.callbacks.preHandleData) {
        info = await state.callbacks.preHandleData({
          data: JSON.parse(JSON.stringify(info)),
          action,
        }) || info
      }
      getEntranceGuide()
      state.description = info.description
      state.groups = info.cartInfo.groups
      state.biData = info.biData
      state.callbacks.onCartUpdate?.({
        action,
        data: info,
      })

      state.metaInfo = info.metaInfo
    } else {
      state.pageError = true
    }
  }

  const fetchCart = async ({ disabledPageLoading, action = UPDATE_CART_INFO_ACTIONS.FETCH }: { disabledPageLoading?: boolean, action?: UPDATE_CART_INFO_ACTIONS } = {}) => {
    try {
      if (state.pageError || !disabledPageLoading) {
        state.pageLoading = true
        state.pageError = false
      }
      // TODO 校验入参
      // if (!option.value.promotionId) return
      await init()
      const commonParams = getCommonParams()
      const res = await apis.fetchCart({ ...commonParams })
      if (res.code === '0') {
        initOverLimitState(res)
        updateShoppingInfo(action, res)
        return true
      } else {
        state.pageError = true
        return false
      }
    } catch(error) {
      console.error('error', error)
      state.pageError = true
      return false
    } finally {
      state.pageLoading = false
    }
  }

  const updateCartItemCheckbox = async (cartUpdateParams) => {
    try {
      const { cartIdList = [], appendIdList = [] } = cartUpdateParams
      const normalCheckIdList = Array.from(new Set([...cartIdList, ...appendIdList])).filter((id) =>
        getters.cartIds.value.includes(id),
      )
      if (state.featureConfig.requireAtLeastOne && cartUpdateParams.operationType === '2' && normalCheckIdList.length >= getters.checkedCartListNum.value) {
        SToast($language.BNonStandardCart.RequireAtLeastOneMsg)
        return false
      }
      state.showLoading = true
      const commonParams = getCommonParams()
      const res = await apis.updateCartItemCheckbox({
        ...commonParams,
        cartUpdateParams,
      })
      if (res.code === '0') {
        await updateShoppingInfo(UPDATE_CART_INFO_ACTIONS.MODIFY, res)
        showOverLimitToast(cartUpdateParams.cartIdList.length > 1 ? -1 : cartUpdateParams.cartIdList[0], res)
        return true
      } else {
        return false
      }
    } catch(error) {
      console.error('error', error)
      return false
    } finally {
      state.showLoading = false
    }
  }

  const updateCartItemSaleAttr = async (cartUpdateParams) => {
    try {
      state.showLoading = true
      const commonParams = getCommonParams()
      const res = await apis.updateCartItemSaleAttr({
        ...commonParams,
        cartUpdateParams,
      })
      if (res.code === '0') {
        await updateShoppingInfo(UPDATE_CART_INFO_ACTIONS.UPDATE_ATTR, res)
        showOverLimitToast(cartUpdateParams.cartId, res)
        return true
      } else {
        SToast(res.msg)
        return false
      }
    } catch(error) {
      console.error('error', error)
      return false
    } finally {
      state.showLoading = false
    }
  }

  const updateCartItemQty = async (cartUpdateParams) => {
    try {
      state.showLoading = true
      const commonParams = getCommonParams()
      const res = await apis.updateCartItemQty({
        ...commonParams,
        cartUpdateParams,
      })
      if (res.code === '0') {
        await updateShoppingInfo(UPDATE_CART_INFO_ACTIONS.UPDATE_QUANTITY, res)
        showOverLimitToast(cartUpdateParams.cartId, res)
        return true
      } else {
        SToast(res.msg)
        return false
      }
    } catch(error) {
      console.error('error', error)
      return false
    } finally {
      state.showLoading = false
    }
  }

  const delCartItemByIds = async (cartIdList) => {
    try {
      state.showLoading = true
      const commonParams = getCommonParams()
      const res = await apis.delCartItemByIds({
        ...commonParams,
        cartUpdateParams: {
          cartIdList,
        },
      })
      if (res.code === '0') {
        await updateShoppingInfo(UPDATE_CART_INFO_ACTIONS.DELETE, res)
        showOverLimitToast(cartIdList[0], res)
        return true
      } else {
        return false
      }
    } catch(error) {
      console.error('error', error)
      return false
    } finally {
      state.showLoading = false
    }
  }

  const checkStock = async () => {
    try {
      state.showLoading = true
      const res = await apis.checkStock()
      return res
    } catch(error) {
      console.error('error', error)
      state.pageError = true
      return false
    } finally {
      state.showLoading = false
    }
  }

  return {
    fetchCart,
    updateCartItemCheckbox,
    updateCartItemSaleAttr,
    updateCartItemQty,
    delCartItemByIds,
    checkStock,
  }
}
