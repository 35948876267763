<template>
  <div
    class="bsc-nsc-skeleton"
    :style="{ height }"
  >
    <div class="bsc-nsc-skeleton-header">
      <div class="bsc-nsc-skeleton-header__content">
        <div class="bsc-nsc-skeleton-header__line"></div>
      </div>
    </div>
    <div class="bsc-nsc-skeleton-body">
      <div class="bsc-nsc-skeleton-body__title">
        <div class="bsc-nsc-skeleton-body__title-left bsc-nsc-skeleton_animation"></div>
        <div class="bsc-nsc-skeleton-body__title-right bsc-nsc-skeleton_animation"></div>
      </div>
      <div
        v-for="i in count"
        :key="i"
        class="bsc-nsc-skeleton-body__item"
      >
        <div class="bsc-nsc-skeleton-body__item-left bsc-nsc-skeleton_animation"></div>
        <div class="bsc-nsc-skeleton-body__item-center">
          <div class="bsc-nsc-skeleton-body__item-line bsc-nsc-skeleton_animation"></div>
          <div class="bsc-nsc-skeleton-body__item-line2 bsc-nsc-skeleton_animation"></div>
        </div>
        <div class="bsc-nsc-skeleton-body__item-right bsc-nsc-skeleton_animation"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  height: {
    type: String,
    default: '',
  },
  count: {
    type: Number,
    default: 12,
  },
})
</script>

<style lang="less">
@header-height: 40px;

.bsc-nsc-skeleton {
  height: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}
.bsc-nsc-skeleton-header {
  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 8px;
    height: @header-height;
    background: transparent;
  }
  &__line {
    height: 16px;
    width: 30%;
    max-width: 100px;
    background-color: #fff;
  }
}

.bsc-nsc-skeleton-body {
  margin: 0 8px;
  background-color: #fff;
  border-radius: 12px;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  &__title-left {
    width: 215px;
    height: 17px;
    background-color: #f5f5f5;
  }

  &__title-right {
    width: 60px;
    height: 17px;
    background-color: #f5f5f5;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  &__item-left {
    width: 74px;
    height: 74px;
    background-color: #f5f5f5;
  }

  &__item-content {
    flex: 1;
    margin-left: 12px;
  }

  &__item-line {
    width: 168px;
    height: 14px;
    background-color: #f5f5f5;
  }

  &__item-line2 {
    margin-top: 8px;
    width: 68px;
    height: 17px;
    background-color: #f5f5f5;
  }

  &__item-right {
    margin-top: 43px;
    width: 40px;
    height: 20px;
    background-color: #f5f5f5;
  }
}

.bsc-nsc-skeleton_animation {
  background-image: linear-gradient(90deg, #f2f2f2 25%, #f9f9f9 38%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: bsc-nsc-animation_loading 2.5s ease infinite;
}

@keyframes bsc-nsc-animation_loading {
  0% {
    background-position: 100% 50%;
  }

  to {
    background-position: 0 50%;
  }
}
</style>
