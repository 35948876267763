import { DURATION } from './constants'

export const wingWithDuration = (wingPromise: Promise<any>, duration = DURATION) => {
  return new Promise(r => {
    let timer
    timer = setTimeout(() => {
      console.error(`Wing timeout exceeded ${DURATION}ms during`, wingPromise)
      r(null)
      clearTimeout(timer)
      timer = null
    }, duration)
    wingPromise.then(res => {
      clearTimeout(timer)
      timer = null
      r(res)
    }).catch(() => {
      clearTimeout(timer)
      timer = null
      r(null)
    })
  })
}
